import React, { useEffect, useRef } from 'react';
import { ButtonEdge } from '../../../components/buttonsOg';
import { useState } from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

function EnrollmentInfo() {
    return (
        <div className="flex gap-1.5 items-center self-center px-2.5 py-1.5 text-center text-gray-800 whitespace-nowrap bg-neutral-50 rounded-[40px]">
            <div className=' flex '>
                <img
                    loading="lazy"
                    src="/assets/temp/student_avatar.png"
                    alt=""
                    className="object-contain shrink-0 self-stretch my-auto aspect-[3.48] h-7 w-7"
                />
                <img
                    loading="lazy"
                    src="/assets/temp/student_avatar_2.png"
                    alt=""
                    className="object-contain shrink-0 self-stretch my-auto aspect-[3.48] h-7 w-7 ml-[-8px]"
                />
                <img
                    loading="lazy"
                    src="/assets/temp/student_avatar.png"
                    alt=""
                    className="object-contain shrink-0 self-stretch my-auto aspect-[3.48] h-7 w-7 ml-[-8px]"
                />
                <img
                    loading="lazy"
                    src="/assets/temp/student_avatar_2.png"
                    alt=""
                    className="object-contain shrink-0 self-stretch my-auto aspect-[3.48] h-7 w-7 ml-[-8px]"
                />
                <img
                    loading="lazy"
                    src="/assets/temp/student_avatar_2.png"
                    alt=""
                    className="object-contain shrink-0 self-stretch my-auto aspect-[3.48] h-7 w-7 ml-[-8px]"
                />
            </div>
            {/* <div className=' bg-yellow-500 h-7 w-7 rounded-full'></div> */}
            <div className="self-stretch my-auto text-base tracking-tight leading-7 rotate-[-0.0030319990213347105rad]">
                +
            </div>
            <div className="flex gap-0.5 items-start self-stretch my-auto font-medium h-[18px] rotate-[-0.0030319990213347105rad] w-[79px]">
                <div className="text-base leading-none">40</div>
                <div className="text-sm leading-none">students</div>
            </div>
        </div>
    );
}


function CourseDetails() {
    return (
        <>
            <time className="mt-5 text-xs text-slate-500">1 - 28 July 2022</time>
            <h2 className="mt-2 text-base font-extrabold tracking-wide leading-6 text-pumpkinToast-500 text-left ">
                Product Management Basic - Course
            </h2>
            <p className="mt-4 text-xs leading-4 text-neutral-600 text-left">
                Product Management Masterclass, you will learn with Sarah Johnson - Head of Product Customer Platform Gojek Indonesia.
            </p>
        </>
    );
}

function PriceInfo() {
    return (
        <div className="flex gap-10 justify-between w-full items-center  mt-8">
            <div className="flex gap-2 items-center pb-2.5 my-auto w-[97px]">
                <div className="grow text-lg font-bold text-black whitespace-nowrap">$ 380</div>
                <div className="text-base text-stone-900 whitespace-nowrap">$ 500</div>
            </div>
            <ButtonEdge variant='primary' text='Enroll Now' className=' min-w-[100px]' />
        </div>
    );
}


function CourseCard() {
    return (
        <article className="flex overflow-hidden flex-col pb-6 bg-white rounded-2xl shadow-2xl w-[296px]">
            <img
                loading="lazy"
                src="/assets/temp/popular_course.png"
                alt="Product Management Course"
                className="object-contain w-full aspect-[1.72]"
            />
            <div className="flex z-10 flex-col items-start px-4 -mt-3.5 w-full">
                <EnrollmentInfo />
                <CourseDetails />
                <PriceInfo />
            </div>
        </article>
    );
}
function CourseCategoryCarousel() {
    const scrollContainerRef = useRef(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);

    const checkScrollPosition = () => {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
        setIsAtStart(scrollLeft === 0);
        setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
    };

    const handlePrev = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: -300,
                behavior: 'smooth',
            });
        }
    };

    const handleNext = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: 300,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        const container = scrollContainerRef.current;
        checkScrollPosition(); // Check initially
        container.addEventListener('scroll', checkScrollPosition);

        return () => container.removeEventListener('scroll', checkScrollPosition);
    }, []);

    return (
        <div className='relative'>
            <div
                className='no-scrollbar overflow-x-scroll w-full scrollbar-hide md:overflow-x-auto md:scroll-smooth  z-10 mt-16 pb-16'
                ref={scrollContainerRef}
            >
                <div className='flex gap-7 w-min mx-auto relative z-10 px-9 sm:px-20'>
                    <CourseCard />
                    <CourseCard />
                    <CourseCard />
                    <CourseCard />
                    <CourseCard />
                    <CourseCard />
                </div>
            </div>
            {!isAtStart && (
                <button
                    className='top-1/2 -translate-y-1/2 absolute z-10 left-5 rounded-full px-3 py-2 bg-pumpkinToast-500  shadow-md flex items-center'
                    onClick={handlePrev}
                >
                    <IconChevronLeft className='text-white text-2xl' />
                </button>
            )}
            {!isAtEnd && (
                <button
                    className='top-1/2 -translate-y-1/2 absolute z-10 right-5 rounded-full px-3 py-2 bg-pumpkinToast-500  shadow-md flex items-center'
                    onClick={handleNext}
                >
                    <IconChevronRight className='text-white text-2xl' />
                </button>
            )}
        </div>
    );
}

export { CourseCategoryCarousel };

import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../../components/adminAxios";
import { useNavigate, useParams } from "react-router-dom";
import { OffcanvasComponent } from "../../../components/offcanvas";
import { IconLoader } from "@tabler/icons-react";
import { Controller, useForm } from "react-hook-form";
import { ADMIN_URL_PATH } from "../../../App";

function StudentEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [division, setdivision] = useState([]);

  const [studentdatas, setStudentData] = useState('')
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      M05_UserName: '',
      M05_UserCode: '',
      M05_CountryName: '',
      M05_DOB: '',
      M05_Gender: '',
      M05_Qualification: '',
      M05_Address: '',
      M05_MobileNumber: '',
      M05_Email: '',
      // M05_LoginUserName: '',
      M05_JoiningDate: '',
      roles: [],
      M05_AllowLogin: false,
      M05_IsActive: false,
    },
  });
  console.log(studentdatas, 'fdfdfdfdfdf');
  const fetchDivision = async () => {
    try {
      const response = await api.get('division');
      const batchData = response.data.data;
      const fetchDivision = batchData.map((division) => ({
        value: division.M11_Division_id,
        label: division.M11_Division_name
      }));
      setdivision(fetchDivision);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const [roles, setRoles] = useState([]);


  useEffect(() => {
    fetchRoles();
    getStudent();
    fetchDivision();
  }, []);

  const getStudent = async () => {
    try {
      const response = await api.get(`/students/${id}`);
      console.log(response, 'response of getStudent');
      setStudentData(response.data.data)
      const studentData = response.data.data;
      console.log(studentData)
      // Reset the form with the fetched data
      reset({
        M05_UserName: studentData.M05_UserName || '',
        M05_UserCode: studentData.M05_UserCode || '',
        M05_CountryName: studentData.M05_CountryName || '',
        M05_DOB: studentData.M05_DOB || '',
        M05_Gender: studentData.M05_Gender || '',
        M05_Qualification: studentData.M05_Qualification || '',
        M05_Address: studentData.M05_Address || '',
        M05_MobileNumber: studentData.M05_MobileNumber || '',
        M05_Email: studentData.M05_Email || '',
        // M05_LoginUserName: studentData.M05_LoginUserName || '',
        M05_JoiningDate: studentData.M05_JoiningDate || '',
        roles: studentData.roles || [],
        M05_AllowLogin: studentData.M05_AllowLogin || false,
        M05_IsActive: studentData.M05_IsActive || false,
        M07_M11_DivisionId:studentData.student?.M07_M11_DivisionId
      });
    } catch (error) {
      console.log(error);
    }
  };


  const fetchRoles = async () => {
    try {
      const response = await api.get("/roles");
      const filteredRoles = response.data.data.map(role => ({
        value: role.M04_RoleId,
        label: role.M04_RoleName,
      }));
      setRoles(filteredRoles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const onSubmit = async (formData) => {
    setLoading(true)
    try {
      console.log('Submitting form data:', formData);
      const response = await api.put(`/users/${id}`, formData);
      console.log('Update response:', response);
      navigate(`/${ADMIN_URL_PATH}/adminpanel`);
    } catch (error) {
      if (error.response && error.response.status === 422 && error.response.data.errors) {
        // Handle validation errors
        const validationErrors = error.response.data.errors;

        // Use setError to assign server errors to form fields
        Object.keys(validationErrors).forEach((field) => {
          setError(field, {
            type: "server",
            message: validationErrors[field].join(", "), // Combine multiple error messages if any
          });
        });
      } else {
        console.error("Error submitting form:", error);
        alert(error.response.data.errors || 'Error Updating sutdent')
      }
    }
    setLoading(false)
  };


  return (
    <div style={{ backgroundColor: "#dff7ed" }} className="w-full min-h-screen flex justify-center items-center p-8 overflow-auto">

      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-semibold text-green-900 mb-10">
          Edit Student Details
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
        >
          <Controller
            name="M05_UserName"
            control={control}
            rules={{ required: "User Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_UserName"
                  className="block text-sm font-medium text-gray-700"
                >
                  User Name
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_UserName"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Ebraham Koshi"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_UserCode"
            control={control}
            rules={{ required: "User Code is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_UserCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  User Code
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_UserCode"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="##001"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_CountryName"
            control={control}
            rules={{ required: "Country Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_CountryName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country Name
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_CountryName"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="India"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />


          <Controller
            name="M05_DOB"
            control={control}
            rules={{ required: "Date of Birth is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_DOB"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date of Birth
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="date"
                  id="M05_DOB"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          {/* <Controller
            name="M05_M02_BachId"
            control={control}
            rules={{ required: "Batch ID is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_M02_BachId"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                >
                  Batch ID
                </label>
                <Select
                  {...field}
                  options={batches}
                  placeholder="Select Batch"
                  styles={customStyles}
                  className={`react-select-container ${error ? "error-border" : ""
                    }`}
                  classNamePrefix="react-select"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          /> */}
          <Controller
            name="M05_Gender"
            control={control}
            rules={{ required: "Gender is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Gender"
                  className="block text-sm font-medium text-gray-700"
                >
                  Gender
                </label>
                <select
                  {...field}
                  id="M05_Gender"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Qualification"
            control={control}
            rules={{ required: "Qualification is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Qualification"
                  className="block text-sm font-medium text-gray-700"
                >
                  Qualification
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_Qualification"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Plus Two"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_MobileNumber"
            control={control}
            rules={{
              required: "Mobile Number is required",
              pattern: {
                value: /^\+?[1-9]\d{1,14}$/,
                message: "Invalid phone number",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_MobileNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mobile Number
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="tel"
                  id="M05_MobileNumber"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="+91 1234567890"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="email"
                  id="M05_Email"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Ebraham Koshi"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          {/* <Controller
            name="M05_LoginUserName"
            control={control}
            rules={{ required: "Login Username is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_LoginUserName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Login Username
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_LoginUserName"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Username"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          /> */}

          {/* <Controller
            name="M05_Password"
            control={control}
            rules={{
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_Password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="password"
                  id="M05_Password"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="min 8 letters"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          /> */}

          <Controller
            name="M05_JoiningDate"
            control={control}
            rules={{ required: "Joining Date is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_JoiningDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Joining Date
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="date"
                  id="M05_JoiningDate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          {/* <Controller
            name="M05_TerminationDate"
            control={control}
            render={({ field }) => (
              <div>
                <label
                  htmlFor="M05_TerminationDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Termination Date
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="date"
                  id="M05_TerminationDate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
              </div>
            )}
          /> */}

          {/* <Controller
            name="M05_BloodGroup"
            control={control}
            rules={{ required: "Blood Group is required" }}
            render={({ field, fieldState: { error } }) => (
              <div>
                <label
                  htmlFor="M05_BloodGroup"
                  className="block text-sm font-medium text-gray-700"
                >
                  Blood Group
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_BloodGroup"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          /> */}

          {/* <Controller
            name="M05_M04_Role"
            control={control}
            rules={{ required: "At least one role is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="col-span-2 mb-4 sm:mb-0">
                <label
                  htmlFor="M05_M04_Role"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Roles
                </label>
                <Select
                  {...field}
                  options={roles}
                  isMulti
                  isDisabled={loading}
                  placeholder="Select Roles"
                  styles={customStyles}
                  className={`react-select-container ${error ? "error-border" : ""
                    }`}
                  classNamePrefix="react-select"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          /> */}

          <Controller
            name="M07_M11_DivisionId"
            control={control}
            rules={{ required: "Division is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M07_M11_DivisionId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Division
                </label>
                <select
                  {...field}
                  id="M07_M11_DivisionId"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                >
                  <option value="">Select Division</option>
                  {division.map(
                    (divs) => {
                      return <option key={divs.value} value={divs.value}>{divs.label}</option>
                    }
                  )}
                </select>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_Address"

            control={control}
            rules={{ required: "Address is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="col-span-2 mb-4 sm:mb-4">
                <label
                  htmlFor="M05_Address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address
                </label>
                <textarea
                  {...field}
                  disabled={loading}
                  id="M05_Address"
                  rows="1"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                ></textarea>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M05_AllowLogin"
            control={control}
            render={({ field }) => (
              <div className="flex items-center mb-4 sm:mb-0">
                <input
                  {...field}
                  disabled={loading}
                  type="checkbox"
                  id="M05_AllowLogin"
                  className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                />
                <label
                  htmlFor="M05_AllowLogin"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Allow Login
                </label>
              </div>
            )}
          />

          <Controller
            name="M05_IsActive"
            control={control}
            render={({ field }) => (
              <div className="flex items-center mb-4 sm:mb-0">
                <input
                  {...field}
                  disabled={loading}
                  type="checkbox"
                  id="M05_IsActive"
                  className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                />
                <label
                  htmlFor="M05_IsActive"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Is Active
                </label>
              </div>
            )}
          />

          <div className="col-span-full">
            <button
              disabled={loading}
              type="submit"
              className="w-full py-2 px-4 flex gap-2 justify-center bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {loading && <IconLoader className="animate-spin" />}
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default StudentEdit;

import React from 'react'
import { LatestImagesCarousel } from './components/LatestImagesCarousel'
import { EventsModal } from './components/eventsModal'

export  function OurEvents() {

    const images = [
        {
            title:'Event Name here',
            image:"/assets/temp/imageGroup.png",
            content:'Let’s embody your beautiful ideas together, simplify the way you visualize your next big things.'
        },
        {
            title:'Event Name 2',
            image:"/assets/temp/imageGroup.png",
            content:'Darul Hadees is a platform that enables students to learn from expert scholars and instructors. It provides a comprehensive and structured approach to Islamic education'
        },
    ]

  return (
    <section className='  py-20 flex flex-col gap-10  relative z-10'>
        <p className='font-light text-[#24485D] text-center ' style={{fontSize:'clamp(30px, 3vw, 45px)'}}>Our Events</p>
        <EventsModal elements={images}/>
    </section>
  )
}

import React from 'react'
import { IconPointFilled } from '@tabler/icons-react';
import { ButtonEdge } from '../../components/buttonsOg';
import { CourseCard } from './components/courceCard';
import { CourceCategoryCard } from './components/courceCategoryCard';

export function CouseCategory() {
    const courseCategories = [
        {
            title: "Web Development",
            content: "Learn the fundamentals of web development, including HTML, CSS, and JavaScript.",
            titleImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/7b4cb682351778c04f6704d27b6832412d699c74bce3d3ffe5f123722f4f3d73?placeholderIfAbsent=true&apiKey=1d02b740da1d48a8a50be2b704c08715",
        },
        {
            title: "Data Science",
            content: "Discover the world of data science, including machine learning, data visualization, and statistics.",
            titleImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/7b4cb682351778c04f6704d27b6832412d699c74bce3d3ffe5f123722f4f3d73?placeholderIfAbsent=true&apiKey=1d02b740da1d48a8a50be2b704c08715",
        },
        {
            title: "Artificial Intelligence",
            content: "Explore the latest advancements in artificial intelligence, including natural language processing and computer vision.",
            titleImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/7b4cb682351778c04f6704d27b6832412d699c74bce3d3ffe5f123722f4f3d73?placeholderIfAbsent=true&apiKey=1d02b740da1d48a8a50be2b704c08715",
        },
    ];
    return (
        <div className='w-full  text-center md:py-14 py-5 md:pt-24 pt-10'>
            {/* POPULAR COURSES HEADING */}
            <div className=' mx-auto w-min relative'>
                <h2 className="lg:text-5xl whitespace-nowrap font-bold tracking-tighter leading-tight text-pumpkinToast-500 max-w-[340px] mx-auto -rotate-[0.174deg]" style={{fontSize:'clamp(30px, 3vw, 45px)'}}>
                    Courses Category
                </h2>
                <div className=' absolute -bottom-3 -right-9'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="10" viewBox="0 0 178 10" fill="none" className='w-[120px] md:w-[178px]'>
                        <path d="M1 8.30692C49.5338 0.217925 116.717 -0.456198 177.834 8.30692" stroke="black" stroke-width="2.24694" />
                    </svg>
                </div>
            </div>
            {/* Description */}
            <p className=' max-w-[600px] mx-auto font-thin text-sm md:text-xl mt-11 px-5'>Onlearing  is one powerful online software suite that combines all the tools needed to run a successful school or office.</p>
            
            <div className=' flex flex-wrap justify-center  mx-auto gap-5 gap-y-11 mt-8 px-5'>
                {courseCategories.map((category, index) => (
                    <CourceCategoryCard key={index} {...category} />
                ))}
            </div>
            <ButtonEdge text='view all' variant={'outlined'} className='mt-14 w-[100px]'  />
        </div>
    )
}


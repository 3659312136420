import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import unionImage from './Union2x.png';
import { IconUsersGroup, IconChevronRight, IconChevronLeft } from '@tabler/icons-react';
import Footer from '../home/footer';

gsap.registerPlugin(ScrollTrigger);

export default function About() {
  const [currentImage, setCurrentImage] = useState(0);
  const [showButtons, setShowButtons] = useState(false);
  const containerRef = useRef(null);
  const card1Ref = useRef(null);
  const card2Ref = useRef(null);
  const card3Ref = useRef(null);

  useEffect(() => {
    const cards = [card1Ref.current, card2Ref.current, card3Ref.current];
    
    cards.forEach((card, index) => {
      gsap.fromTo(card, 
        {
          y: 100,
          opacity: 0
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: card,
            start: "top bottom-=100",
            end: "top center",
            scrub: true,
            toggleActions: "play none none reverse"
          }
        }
      );
    });
  }, []);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const hasOverflow = containerRef.current.scrollWidth > containerRef.current.clientWidth;
        setShowButtons(hasOverflow);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  const images = [
    "https://www.uvm.edu/~hp206/2011/hamer/Hameruniversitygreen/85sprospect.jpg",
    "https://images.unsplash.com/photo-1567345242672-1c9cd67cb860?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8OSUzQTE2fGVufDB8fDB8fHww",
    "https://images.unsplash.com/photo-1560356062-e67bd9e034d5?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDB8fDklM0ExNnxlbnwwfHwwfHx8MA%3D%3D"
  ];

  const handleProgressClick = (index) => {
    setCurrentImage(index);
  };

  const getImageStyle = (index) => {
    if (index === currentImage) {
      return 'z-30 scale-100 rotate-0 md:top-[100px] top-[30px] md:left-[50px]';
    } else if (index === (currentImage + 1) % images.length) {
      return 'z-20 scale-95 rotate-[10deg] md:top-[110px] top-[30px] md:left-[60px]';
    } else {
      return 'z-10 scale-90 rotate-[20deg] md:top-[120px] top-[50px] md:left-[70px]';
    }
  };

  return (
    <div className='pt-[60px] relative'>
      <div className='bg-[rgba(255,200,100,1)] mt-[60px] w-[300px] h-[300px] absolute blur-[160px] z-0 top-0'></div>
      <div className="mainContent w-full min-h-[550px] flex flex-col justify-between md:flex-row md:px-20 sm:px-10 px-5">
        {/* Image Section */}
        <div className='w-full md:w-1/2 flex justify-center h-full relative min-h-[250px]'>
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt=""
              className={`lg:w-[280px] lg:h-[350px] sm:w-[200px] sm:h-[250px] w-[150px] h-[200px] absolute transition-all duration-700 ease-in-out ${getImageStyle(index)}`}
            />
          ))}
        </div>

        {/* Text Section */}
        <div className='w-full md:w-1/2 flex flex-col justify-center gap-3 order-1 md:order-2 py-3 md-py-0'>
          <h1 className='text-center md:text-end' style={{fontSize:'clamp(30px, 4vw, 45px)'}}>About Us</h1>
          <p className='text-sm md:text-base'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, nihil! Quaerat error, commodi mollitia iste tenetur ipsa exercitationem maiores sint quos omnis cum. Explicabo non nesciunt sed autem fugiat voluptas.
            Hic, voluptatibus, commodi eius laboriosam quia enim numquam iure iste explicabo libero, nihil ut rem voluptatum necessitatibus ratione consectetur. Error architecto repellendus asperiores quas tenetur laudantium tempore deserunt consequatur! Ducimus?</p>

          {/* Progress Circles */}
          <div className="flex gap-3 mt-5 justify-center">
            {images.map((_, index) => (
              <div
                key={index}
                className={`h-[8px] w-[33%] rounded-full cursor-pointer relative overflow-hidden ${currentImage === index ? 'bg-gray-300' : 'bg-gray-100'
                  }`}
                onClick={() => handleProgressClick(index)}
              >
                <div
                  className={`absolute top-0 left-0 h-full bg-pumpkinToast-500 transition-all duration-700 ease-in-out ${currentImage === index ? 'w-full' : 'w-0'
                    }`}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>


      {/* Objectives Section */}
      <div className='h-min md:h-[500px] bg-center bg-no-repeat w-full flex flex-wrap md:px-20 sm:px-10 px-5 py-24 md:py-16 md:bg-100% bg-cover' style={{ backgroundImage: `url(${unionImage})` }}>
        <div className='lg:w-1/2 w-full h-min'>
          <h1 style={{ fontSize: 'clamp(20px, 3vw, 36px)' }} className='text-center md:text-start mb-5 md:mb-0 md:mt-10'>Objectives</h1>
        </div>
        <div className='lg:w-1/2 w-full flex flex-col justify-start gap-3 '>
          <p className='text-sm md:text-base'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque, aut, ratione porro quam nesciunt hic voluptatibus aperiam expedita</p>
          <p className='text-sm md:text-base'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magnam velit fugit odio ab, asperiores tempora reiciendis dignissimos quia reprehenderit aspernatur beatae vero voluptatibus quos debitis eveniet, voluptates maxime autem! Eaque!
            Facilis, quos atque iure blanditiis porro necessitatibus quod fugit neque obcaecati? Facere unde aliquam porro accusamus inventore pariatur tempore, itaque vitae sint incidunt architecto blanditiis reprehenderit illum nobis corporis fugiat.</p>
          <p className='text-base md:text-xl font-light text-[yellow] text-start md:mt-0 lg:mt-5 mt-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
        </div>
      </div>

      {/* Vision Section */}
      <div className='visionCard md:px-20 sm:px-10 px-5 relative'>
        <div className='bg-[rgba(255,200,100,1)] mt-[60px] w-[300px] h-[300px] absolute blur-[160px] z-0 top-0 right-0'></div>
        <h1 className='md:py-20 py-5 text-center md:text-left' style={{ fontSize: 'clamp(20px, 3vw, 36px)' }}>A Vision To Success</h1>

        {/* Adjust grid layout */}
        <div className='cardContainer grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-16 pt-10 md:pb-32 pb-5 z-10 relative'>
          <div 
            ref={card1Ref}
            className='w-full bg-[rgba(217,217,217,0.2)] p-5 rounded-md z-10'
          >
            <p className='mb-5 text-xl md:font-bold font-semibold'>Inspire</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi deserunt sapiente qui temporibus adipisci enim unde eos soluta voluptas nisi</p>
          </div>
          <div 
            ref={card2Ref}
            className='w-full bg-[rgba(217,217,217,0.2)] p-5 rounded-md z-10'
          >
            <p className='mb-5 text-xl md:font-bold font-semibold'>Empower</p>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo, reiciendis. Dolor, quae. Cumque, temporibus iusto consectetur modi laborum</p>
          </div>
          <div 
            ref={card3Ref}
            className='w-full bg-[rgba(217,217,217,0.2)] p-5 rounded-md z-10'
          >
            <p className='mb-5 text-xl md:font-bold font-semibold'>Thrive</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, delectus, optio placeat molestias soluta corrupti, id facilis facere</p>
          </div>
        </div>
      </div>



      {/* Team Members Section */}
      <div className='teamMembers md:px-20 sm:px-10 px-5 pt-10'>
        <button className='flex gap-1 justify-center rounded-md w-[150px] bg-[rgba(217,217,217,0.3)] text-gentianBlue-500 py-1'>
          <IconUsersGroup stroke={2} /> Our Team
        </button>
        <h1 className='mt-2' style={{ fontSize: 'clamp(25px, 3vw, 36px)' }}>Meet Our Team Members</h1>
        <p className='text-sm md:text-base'>Complete the form below to send us a message. Our support team will promptly respond to your request.</p>

        <div className="relative">
          {/* Carousel container */}
          <div
            ref={containerRef}
            className={`personCardContainer w-full h-min flex gap-7 my-16 overflow-x-auto overflow-y-hidden ${showButtons ? 'justify-start' : 'justify-center'}`}
          >
            {/* Team Members */}
            {[...Array(6)].map((_, index) => (
              <div key={index} className='w-[120px] flex-shrink-0'>
                <div className='h-1/2 w-full'>
                  <img
                    src="https://passport-photo.online/images/cms/prepare_light_b364e3ec37.webp?quality=80&format=webp&width=1920"
                    alt=""
                    className='h-full w-full rounded-full'
                  />
                </div>
                <div className='flex flex-col items-center'>
                  <p className='font-bold mt-3'>John Ruther</p>
                  <p className='font-light text-gentianBlue-500 text-sm'>
                    {index === 0 ? 'Chemistry' : index === 1 ? 'English' : 'Other Subject'}
                  </p>
                  <p className='font-light text-xs mt-2 text-center'>Pop music lover, seeks joy and exciting pop concerts</p>
                </div>
              </div>
            ))}
          </div>

          {/* Scroll buttons */}
          {showButtons && (
            <>
              <button
                onClick={() => handleScroll('left')}
                className='absolute top-1/2 left-0 transform -translate-y-1/2 p-2 bg-gray-200 rounded-full'
              >
                <IconChevronLeft stroke={2} />
              </button>
              <button
                onClick={() => handleScroll('right')}
                className='absolute top-1/2 right-0 transform -translate-y-1/2 p-2 bg-gray-200 rounded-full'
              >
                <IconChevronRight stroke={2} />
              </button>
            </>
          )}
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}
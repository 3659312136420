import React, { useState, useEffect } from 'react';
import api from '../../../components/adminAxios';
import { useNavigate } from 'react-router-dom';
import { IconLoader } from '@tabler/icons-react';
import { ADMIN_URL_PATH } from '../../../App';

function TeacherDetails({ id, toggleModal,setModal }) {
    const navigate = useNavigate()
    const [teacher, setTeacher] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    console.log("teacher ID id", id)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const getData = async () => {
        try {
            console.log(id, 'helloo namasthe');

            const response = await api.get(`/division/${id}`);
            console.log(response)
            setTeacher(response.data.data);
            console.log('hellooioiioi', response)
            console.log("teacher is", teacher)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getData();
    }, [id]);

    const handleDeleteModalToggle = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleDeleteTeacher = async () => {
        setIsDeleteLoading(true);
        if (!teacher) return;
        try {
            const response = await api.delete(`/division/${teacher.M11_Division_id}`);
            if (response.data.success === true) {
                setModal(false)
                setIsDeleteModalOpen(false)
            } else {
                throw new Error(response.data.message);
            }

        } catch (error) {
            console.log(error);
            alert(error.response?.data?.message || 'something went wrong')
        }finally {
            setIsDeleteLoading(false);
        }
    }

    const handleEdit = () => {
        if (!teacher) return;
        try {
            navigate(`/${ADMIN_URL_PATH}/division_edit/${teacher.M11_Division_id}`)
        } catch (error) {
            console.log(error);
        }
    }

    if (!teacher) {
        return <div style={{ fontFamily: 'sans-serif' }}>Loading...</div>;
    }

    return (
        <div>
            <div
                id="crud-modal"
                tabIndex="-1"
                aria-hidden="true"
                className="fixed inset-0 z-50 flex justify-center  items-center overflow-y-auto bg-gray-800 bg-opacity-50"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <div
                    style={{ borderRadius: '8px', maxWidth: '600px', width: '100%' }}
                    className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4 mt-18 mb-13"
                >
                    <div className="flex items-center justify-between p-4 md:p-5 border-b dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Division Details
                        </h3>
                        <button
                            onClick={toggleModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-6 rounded-lg shadow-md w-full">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p className="text-2xl font-semibold text-gray-800 mb-4">
                                {teacher.M11_Division_name}'s Details
                            </p>

                            <svg onClick={() => handleEdit(teacher.M11_Division_id)} style={{ color: 'gray', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mb-3 ">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                            </svg>

                        </div>
                        <div className="f">
                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Name:</p>
                                <p className="text-gray-900">{teacher.M11_Division_name}</p>
                            </div>

                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Batch :</p>
                                <p className="text-gray-900">{teacher.batch.M02_Name}</p>
                            </div>
                            <div className="flex justify-between items-center border-b pb-4">
                                <p className="font-medium text-gray-600">Mentor Name :</p>
                                <p className="text-gray-900">{teacher.mentor?.M05_UserName}</p>
                            </div>

                            
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <button
                                onClick={handleDeleteModalToggle} // Toggle the delete modal on click
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    backgroundColor: '#800000',
                                }}
                                type="button"
                                className="text-gray-900 mt-4 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2"
                            >
                                Delete the Division
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <div id="popup-modal" className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button
                                onClick={handleDeleteModalToggle}
                                    disabled={isDeleteLoading}
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg
                                    className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Are you sure you want to delete this Teacher?
                                </h3>
                                <button
                                    onClick={() => handleDeleteTeacher(teacher.M11_Division_id)}
                                    disabled={isDeleteLoading}
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                                >
                                    {isDeleteLoading&&<IconLoader className=' animate-spin'/>}
                                    Yes, I'm sure
                                </button>
                                <button
                                    onClick={handleDeleteModalToggle}
                                    disabled={isDeleteLoading}
                                    className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-blue rounded-lg border border-blue-800 hover:bg-blue-500 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-blue-500 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                >
                                    No, cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TeacherDetails

import React from 'react'

export const ButtonEdge = ({ variant = 'primary'|'outlined'|'secondary'|'grayButton'|'blueButton',text='button',className='' }) => {
    let classNameCreated = '';

    if (variant === 'primary') {
        classNameCreated = 'bg-pumpkinToast-500 text-white hover:bg-pumpkinToast-400';
    } else if (variant === 'secondary') {
        classNameCreated = 'hover:bg-gentianBlue-500 hover:text-white bg-white text-black shadow-sm';
    }else if (variant === 'grayButton') {
        classNameCreated = 'bg-gray-200 text-pumpkinToast-500 hover:bg-gray-300';
    }else if (variant === 'blueButton') {
        classNameCreated = 'bg-gentianBlue-500/10 text-gentianBlue-500 hover:bg-gentianBlue-500/20';
    } else {
        classNameCreated = 'border border-black text-black  hover:border-gray-800';
    }

    return (
        <button className={`${classNameCreated} gap-2.5 px-5 py-2.5 text-xs font-medium  transition-colors rounded-md  whitespace-nowrap  ${className}`}>
            {text}
        </button>
    );
}



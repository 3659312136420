import React from 'react'
import notFoundImage  from './404notfound.png'
export default function PagenotFount() {
  return (
    <div className="flex flex-col items-center justify-center h-screen ">
    <img src={notFoundImage} alt="404 Not Found" className="w-1/3 mb-8 m-10" />
  </div>
  )
}


import React from 'react';
import { IconPointFilled } from '@tabler/icons-react';

export function HomeContent() {
    return (
        <div className='w-full min-h-[570px] h-[580px] flex flex-wrap-reverse md:flex-nowrap justify-center items-center'>
            <div className='md:w-1/2 h-full flex flex-col lg:ps-28 md:ps-14 px-5 text-left gap-5 md:justify-center justify-end pb-5 md:pb-0 mt-3 md:mt-0'>
                <h1 className='font-bold z-10' style={{ fontSize: 'clamp(25px, 3vw, 40px)' }}>
                    Take student <br /> experience to the next level
                </h1>
                <p className='z-10 text-sm'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </p>
                <button className='bg-[#daa658] py-2 rounded-2xl w-[130px] md:mt-10 mt-5 text-white shadow-md hover:bg-[#db9936] shadow-gray-500 z-10'>
                    Join Now
                </button>
            </div>

            <div className='md:w-1/2 w-full h-fit md:h-full flex justify-start items-end relative pb-20 ps-4'>
                {/* First Image Div */}
                <img
                    src="/assets/building.jpg"
                    alt=""
                    className='rounded-md lg:h-[280px] lg:w-[420px] md:h-[220px] md:w-[300px] sm:w-[420px] sm:h-[280px] h-[200px] w-[300px] z-10 absolute md:top-[150px] top-[200px] md:right-[95px] right-[7%]'
                />

                {/* Second Border Div with a consistent 20px offset */}
                <div
                    className='border-2 border-pumpkinToast-500 rounded-md absolute lg:h-[280px] lg:w-[420px] md:h-[220px] md:w-[300px] sm:w-[420px] sm:h-[280px] h-[200px] w-[300px] z-0 top-[210px] right-[5%] md:top-[130px] md:right-[80px]'
                    // style={{ top: '230px', right: '50px' }} // 20px offset applied
                ></div>

                <div className='tracking-[3px] text-xl font-extrabold leading-none flex flex-col text-gray-500 md:px-10 md:pb-5'>
                    <div className="flex">
                        <IconPointFilled /><IconPointFilled /><IconPointFilled /><IconPointFilled /><IconPointFilled />
                    </div>
                    <div className="flex">
                        <IconPointFilled /><IconPointFilled /><IconPointFilled /><IconPointFilled /><IconPointFilled />
                    </div>
                    <div className="flex">
                        <IconPointFilled /><IconPointFilled /><IconPointFilled /><IconPointFilled /><IconPointFilled />
                    </div>
                </div>
            </div>
        </div>
    );
}

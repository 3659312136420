import React from 'react'
import { Success } from './successCard';
import { PopularCourse } from './popularCourse';
import { CouseCategory } from './couseCatogery';
import { GetIdCard } from './getIdCard';
import { StaffTraining } from './staffTraining';
import Footer from '../home/footer';

export function CoursesPage() {
    return (
        <div className='w-full text-center min-h-screen relative pt-[60px] overflow-x-hidden '>
            <div className='bg-pumpkinToast-500 mt-[60px] w-[400px] h-[400px] absolute blur-[160px] z-0 top-[100px] '></div>
            <div className='bg-pumpkinToast-500 mt-[60px] w-[400px] h-[400px] absolute blur-[160px] z-0 top-[150vh] right-0 '></div>
            <PopularCourse />
            <CouseCategory />
            <div className=' flex flex-col gap-32 pt-8 pb-36'>
                <GetIdCard />
                <StaffTraining />
            </div>
            <Footer/>
            {/* <Success /> */}
        </div>
    )
}

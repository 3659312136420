import React, { useEffect, useState } from 'react';
import api from '../../components/adminAxios';
import { useNavigate } from 'react-router-dom';
import { IconLoader } from '@tabler/icons-react'; // Assuming you are using Tabler icons or similar
import { ADMIN_URL_PATH } from '../../App';

function CourseView() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await api.get('/courses');
      setCourses(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnclick = async (id) => {
    navigate(`/${ADMIN_URL_PATH}/course_edit/${id}`);
  };

  const handleDelete = async () => {
    if (!selectedCourse) return;
    setDeleteLoading(true);
    try {
      const response = await api.delete(`/courses/${selectedCourse.M01_CourseId}`);
      alert(`Course "${selectedCourse.M01_Title}" has been deleted successfully.`);
      setDeleteModalOpen(false);
      getData();
    } catch (error) {
      if (error.response?.status === 400) {
        alert(error.response.data.message);
      } else if(error.response?.status === 403){
        alert(error.response.data.message)
      }else {
        console.log(error);
      }
    } finally {
      setDeleteLoading(false);
      setDeleteModalOpen(false);
    }
  };

  const handleDeleteModalToggle = (course = null) => {
    setSelectedCourse(course);
    setDeleteModalOpen(!isDeleteModalOpen);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ width: '100%', backgroundColor: '#dff7ed', height: '100vh', overflowY: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='w-full p-5 md:px-[80px]'>
        <button
          onClick={() => navigate(`/${ADMIN_URL_PATH}/course_add`)}
          className='btn btninner'
          style={{ fontFamily: 'fantasy', color: 'white', width: '100%' }}
        >
          ADD COURSE
        </button>
      </div>

      <div style={{ boxShadow: '0 4px 8px rgba(0.2, 0.2, 0.2, 0.6)' }} className="bg-green-50 sm:rounded-lg text-center overflow-auto box-border m-5 md:mx-[80px] mb-32">
        <table className="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
          <thead className="text-xs text-white uppercase bg-green-600 dark:text-white">
            <tr>
              <th scope="col" className="px-6 py-3">TITLE</th>
              <th scope="col" className="px-6 py-3">DESCRIPTION</th>
              <th scope="col" className="px-6 py-3">EDIT</th>
              <th scope="col" className="px-6 py-3">DELETE</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4" className="px-6 py-4 text-center">
                  <IconLoader className="animate-spin mx-auto" />
                </td>
              </tr>
            ) : (
              courses.length > 0 ? (
                courses.map((courseItem, index) => (
                  <tr key={index} className="bg-green-100 border-b border-green-300 text-green-500 cursor-pointer">
                    <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap dark:text-green-500">
                      {courseItem.M01_Title}
                    </th>
                    <td className="px-6 py-4">
                      {courseItem.M01_Description}
                    </td>
                    <td className="px-6 py-4">
                      <div onClick={() => handleOnclick(courseItem.M01_CourseId)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                        </svg>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div onClick={() => handleDeleteModalToggle(courseItem)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="px-6 py-4 text-center" style={{ fontWeight: 'bold', color: '#8B0000' }}>
                    NO COURSES FOUND
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>

      {isDeleteModalOpen && (
        <div id="popup-modal" className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                disabled={isDeleteLoading}
                onClick={() => handleDeleteModalToggle(null)}
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1l6 6m0 0 6-6M7 7l6 6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-6 text-center">
                <svg
                  aria-hidden="true"
                  className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 12l6-6m0 0l6 6m-6-6v12"
                  />
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to delete the course <span className="font-semibold">{selectedCourse?.M01_Title}</span>?
                </h3>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  {isDeleteLoading ? 'Deleting...' : 'Yes, Delete'}
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteModalToggle(null)}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  No, Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseView;

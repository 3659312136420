import React, { useEffect, useState } from 'react';
import api from '../../../components/adminAxios';
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_URL_PATH } from '../../../App';

function ClassEdit() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [batchIdMap, setBatchIdMap] = useState({});
    const [batchNames, setBatchNames] = useState([]);
    const [divisionMap, setDivisionMap] = useState({});
    const [divisionNames, setDivisionNames] = useState([]);
    const [formData, setFormData] = useState({
        M03_SubjectName: '',
        M03_Title: '',
        M03_ScheduledAt: '',
        M03_ExpiryTime: '',
        M03_M02_BatchId: '',
        M03_M11_DivisionId: '',
        M03_YouTubeLink: ''
    });
    const [errors, setErrors] = useState({});

    const fetchId = async () => {
        try {
            const response = await api.get("/batch_ids");
            setBatchIdMap(response.data.data);
            setBatchNames(Object.keys(response.data.data));
        } catch (error) {
            console.error("Error fetching batch IDs:", error);
        }
    };

    const fetchDivisions = async () => {
        try {
            const response = await api.get("/division");
            const divisions = response.data.data.reduce((map, division) => {
                map[division.M11_Division_name] = division.M11_Division_id;
                return map;
            }, {});
            setDivisionMap(divisions);
            setDivisionNames(Object.keys(divisions));
        } catch (error) {
            console.error("Error fetching divisions:", error);
        }
    };

    const fetchClass = async () => {
        try {
            const response = await api.get(`/classes/${id}`);
            setFormData({
                M03_SubjectName: response.data.data.M03_SubjectName || '',
                M03_Title: response.data.data.M03_Title || '',
                M03_ScheduledAt: response.data.data.M03_ScheduledAt || '',
                M03_ExpiryTime: response.data.data.M03_ExpiryTime || '',
                M03_M02_BatchId: response.data.data.M03_M02_BatchId || '',
                M03_M11_DivisionId: response.data.data.M03_M11_DivisionId || '',
                M03_YouTubeLink: response.data.data.M03_YouTubeLink || ''
            });
        } catch (error) {
            console.error("Error fetching class:", error);
        }
    };

    useEffect(() => {
        fetchId();
        fetchDivisions();
        fetchClass();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        let newValue = value;
        if (name === "M03_M02_BatchId") {
            newValue = batchIdMap[value] || '';
        }
        if (name === "M03_M11_DivisionId") {
            newValue = divisionMap[value] || '';
        }

        setFormData(prevData => ({
            ...prevData,
            [name]: newValue
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            try {
                await api.put(`/classes/${id}`, formData);
                navigate(`/${ADMIN_URL_PATH}/class`);
            } catch (error) {
                console.error("Error updating class:", error);
                // Handle error (e.g., show error message to user)
            }
        } else {
            setErrors(formErrors);
        }
    };

    const validateForm = () => {
        let formErrors = {};
        const requiredFields = ['M03_SubjectName', 'M03_Title', 'M03_ScheduledAt', 'M03_M02_BatchId', 'M03_M11_DivisionId', 'M03_YouTubeLink'];
        requiredFields.forEach(field => {
            if (!formData[field]) {
                formErrors[field] = `${field.replace('M03_', '').replace('_', ' ')} is required`;
            }
        });
        return formErrors;
    };

    return (
        <div className="bg-green-100 min-h-screen flex items-center justify-center p-4">
            <div className="w-full max-w-4xl bg-green-100 p-8 rounded-lg">
                <h1 className="text-3xl font-semibold text-green-900 mb-8">Edit Class</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Subject</label>
                            <input
                                type="text"
                                name="M03_SubjectName"
                                value={formData.M03_SubjectName}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            />
                            {errors.M03_SubjectName && <span className="text-red-600">{errors.M03_SubjectName}</span>}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Title</label>
                            <input
                                type="text"
                                name="M03_Title"
                                value={formData.M03_Title}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            />
                            {errors.M03_Title && <span className="text-red-600">{errors.M03_Title}</span>}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Batch</label>
                            <select
                                name="M03_M02_BatchId"
                                value={Object.keys(batchIdMap).find(key => batchIdMap[key] === formData.M03_M02_BatchId) || ''}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            >
                                <option value="">Select Batch</option>
                                {batchNames.map(name => (
                                    <option key={name} value={name}>{name}</option>
                                ))}
                            </select>
                            {errors.M03_M02_BatchId && <span className="text-red-600">{errors.M03_M02_BatchId}</span>}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Division</label>
                            <select
                                name="M03_M11_DivisionId"
                                value={Object.keys(divisionMap).find(key => divisionMap[key] === formData.M03_M11_DivisionId) || ''}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            >
                                <option value="">Select Division</option>
                                {divisionNames.map(name => (
                                    <option key={name} value={name}>{name}</option>
                                ))}
                            </select>
                            {errors.M03_M11_DivisionId && <span className="text-red-600">{errors.M03_M11_DivisionId}</span>}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Schedule Time</label>
                            <input
                                type="datetime-local"
                                name="M03_ScheduledAt"
                                value={formData.M03_ScheduledAt}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            />
                            {errors.M03_ScheduledAt && <span className="text-red-600">{errors.M03_ScheduledAt}</span>}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Expire Time</label>
                            <input
                                type="datetime-local"
                                name="M03_ExpiryTime"
                                value={formData.M03_ExpiryTime}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            />
                            {errors.M03_ExpiryTime && <span className="text-red-600">{errors.M03_ExpiryTime}</span>}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">YouTube Link</label>
                            <input
                                type="url"
                                name="M03_YouTubeLink"
                                value={formData.M03_YouTubeLink}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            />
                            {errors.M03_YouTubeLink && <span className="text-red-600">{errors.M03_YouTubeLink}</span>}
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="mt-6 w-full bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                    >
                        Save Changes
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ClassEdit;

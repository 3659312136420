import React from 'react'

export default function Footer() {
    return (
        <>
            <div className='bg-pumpkinToast-500 w-full md:px-20 px-5'>
                <div className="flex flex-col pb-0 md:pb-6 md:pt-20 pt-5 w-full bg-pumpkinToast-500 max-w-[1440px] max-md:mt-10 max-md:max-w-full">
                    <div className="flex flex-wrap md:gap-5 gap-2 justify-between w-full max-w-[1195px] max-md:max-w-full">
                        <div className="flex w-full md:max-w-[200px] sm:w-auto flex-wrap sm:flex-col self-end items-start mb-3 md:mb-0 gap-y-3 gap-x-3 justify-between">
                            <div className="md:text-3xl text-base text-white font-lato flex gap-2 items-center justify-start">
                                <img src="/assets/whiteLogo.png" alt="logo" className='w-[40px] h-[40px] ' />
                                <h1 className='font-bold text-2xl text-white'>DarulHadees</h1>
                            </div>
                            <p className='text-xs whitespace-normal text-start text-gray-300'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam esse odio error quo est excepturi ipsum non neque ducimus similique </p>
                            <div className=" md:text-base text-sm leading-none text-gray-300 max-md:mr-1 md:block hidden">
                                Social Media Links{" "}
                            </div>
                            <div className="flex gap-5 justify-between mx-3.5 mt-1.5 max-md:mx-2.5">
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e700a7cd6152c56a508bbfafe1b57ff8aace5a0d8b2837f0cef27266e75171ad?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712"
                                    className="object-contain shrink-0 self-start aspect-[1.1] w-[23px]" alt='photos'
                                />
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a046f65f9ecb3b28fafbb8a0fdb9562ca2b739f84bf21855dd1d24fcfe1c752d?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712"
                                    className="object-contain shrink-0 w-6 aspect-[1.09]" alt='photos'
                                />
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e2db7b6020f0a27aba6c51f9943d85566c49f463a33a892b5860252436fc6aad?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712"
                                    className="object-contain shrink-0 my-auto aspect-[1.39] w-[25px]" alt='photos'
                                />
                            </div>
                        </div>
                        <div className="flex w-full sm:w-auto sm:flex-col  items-start text-base font-[275] font-josefin text-gray-300 gap-2">
                            <div className="md:text-lg text-sm text-white">Account</div>
                            <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }} >Sign in</div>
                            <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Dashboard</div>
                            <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Monitor</div>
                            <div className="self-stretch text-left" style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Topics</div>
                        </div>
                        <div className="flex w-full sm:w-auto sm:flex-col items-start self-start text-base whitespace-nowrap font-[275] font-josefin text-gray-300 gap-2">
                            <div className="md:text-lg text-sm text-white">About SBA</div>
                            <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Information</div>
                            <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Resources</div>
                            <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Success</div>
                            <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Meet Experts</div>
                        </div>
                        <div className="flex w-full sm:w-auto sm:flex-col items-start text-base font-[275] font-josefin text-gray-300 gap-2">
                            <div className="md:text-lg text-sm text-white">Support</div>
                            <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Contact Us</div>
                            <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>About Us</div>
                            {/* <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Support</div>
                            <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Privacy</div> */}
                        </div>
                        <div className="flex w-full sm:w-auto sm:flex-col self-start text-base font-[275] font-josefin text-gray-300 gap-2">
                            <div className="md:text-lg text-sm text-white self-start">NewsLetter</div>
                            <div className="self-start" style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Careers</div>
                            <div className="self-start" style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}>Others</div>
                            {/* <div style={{ fontSize: 'clamp(10px,1.3vw,18px)' }}></div> */}
                            {/* <button className='bg-pumpkinToast-500 py-1 md:w-[100px] px-2 text-white border-2 border-white rounded-full hover:bg-white hover:text-black shadow-xl text-xs md:text-base'>Submit</button> */}
                        </div>
                    </div>
                    <div className="flex shrink-0 md:mt-14 mt-6 h-px rounded bg-slate-300 max-md:max-w-full" />
                    <div className="self-center text-xs leading-9 text-gray-300">
                        <span className="font-medium">@LogoName 2024</span>
                        <span>. All right reserved</span>
                    </div>
                </div>
            </div>
        </>
    )
}

import React from 'react'

export default function BestMomentSection() {
    return (
        <section className=' min-h-[90vh] py-10 flex w-full relative z-10 '>
            <div className=' flex flex-col md:flex-row grow justify-between items-center gap-10 gap-y-20'>
                {/* Content */}
                <div className=' md:basis-[50%] xl:basis-[60%] flex flex-col gap-10'>
                    <h2 className='xl:text-6xl text-pumpkinToast-500 font-medium text-start' style={{fontSize:'clamp(28px, 3vw, 45px)'}}>Gallery <br/> of our best <br/> Moments</h2>
                    <p className=' text-start text-gray-500'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                </div>
                {/* image */}
                <div className=' px-10 sm:px-32 md:px-0 flex flex-col gap-8 md:basis-[50%] lg:basis-[]'>
                    <div className=' flex flex-col md:flex-row gap-x-20 gap-y-10 items-center '>
                        {/* image 1 */}
                        <div className='   flex flex-col gap-2'>
                            <img src="/assets/temp/bestMoments1.png" className=' md:min-w-[120px] lg:min-w-[150px] xl:min-w-[200px]   w-min shadow-md shadow-gray-500' alt="" />
                            <p className=' text-start '>best college of kerala <span className=' text-pumpkinToast-500'> : 2023</span> </p>
                        </div>
                        {/* image 2 */}
                        <div className=' flex flex-col gap-2'>
                            <img src="/assets/temp/bestMoments2.png" className=' md:min-w-[150px] lg:w-[200px] xl:min-w-[250px]  w-min shadow-md shadow-gray-500' alt="" />
                            <p className=' text-start '>best college of kerala <span className=' text-pumpkinToast-500'> : 2023</span> </p>
                        </div>
                    </div>
                    <div className=' flex justify-center'>
                        {/* image 3 */}
                        <div className='   flex flex-col gap-2'>
                            <img src="/assets/temp/bestMoments3.png" className='   lg:h-[180px] xl:h-[220px]   w-min shadow-md shadow-gray-500' alt="" />
                            <p className=' text-start '>20 years student meet-up <span className=' text-pumpkinToast-500'> : 2023</span> </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
// import axios from "axios";
import Select from "react-select";
import api from "../../../components/adminAxios";
import { Link, useNavigate } from "react-router-dom";
import { IconArrowLeft, IconLoader } from "@tabler/icons-react";
import { ADMIN_URL_PATH } from "../../../App";
// import "./student.css";

export function StudentPage() {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm();
  const [roles, setRoles] = useState([]);
  const [batches, setBatches] = useState([]);
  const [division, setdivision] = useState([]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #dee2e6",
      borderColor: state.isFocused
        ? "#2684FF"
        : errors[state.selectProps.name]
          ? "#ff000080" // Red border for errors
          : "#cccccc",
      "&:hover": {
        borderColor: state.isFocused
          ? "#2684FF"
          : errors[state.selectProps.name]
            ? "#ff000080" // Red border for errors
            : "#cccccc",
      },
      boxShadow: errors[state.selectProps.name]
        ? "0 0 0 1px red"
        : provided.boxShadow,
    }),
  };

  useEffect(() => {
    fetchRoles();
    fetchBatches();
    fetchDivision();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await api.get(
        "https://elearningbackend.prosevo.com/api/new_role"
      );
      const filteredRoles = response.data.data.map((role) => ({
        value: role.M04_RoleId,
        label: role.M04_RoleName,
      }));
      setRoles(filteredRoles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };


  const fetchBatches = async () => {
    try {
      const response = await api.get('batch_ids');
      const batchData = response.data.data;
      const formattedBatches = Object.entries(batchData).map(([label, value]) => ({
        value: value,
        label: label
      }));
      setBatches(formattedBatches);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };
  const fetchDivision = async () => {
    try {
      const response = await api.get('division');
      const batchData = response.data.data;
      const fetchDivision = batchData.map((division) => ({
        value: division.M11_Division_id,
        label: division.M11_Division_name
      }));
      setdivision(fetchDivision);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true); // Set loading to true during form submission
    const formData = new FormData();
    formData.append('M05_User_Type', 'student');

    Object.keys(data).forEach((key) => {
      if (key === "M05_M04_Role") {
        data[key].forEach((role, index) => {
          formData.append(`M05_M04_Role[${index}]`, role.value);
        });
      } else if (key === "M05_M02_BachId") {
        formData.append(key, data[key].value);
      } else if (key === "M05_AllowLogin" || key === "M05_IsActive") {
        formData.append(key, data[key] ? 1 : 0);
      } else {
        formData.append(key, data[key]);
      }
    });

    try {
      const response = await api.post("/users", formData);
      navigate(`/${ADMIN_URL_PATH}/adminpanel`);
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      if (error.response && error.response.status === 422 && error.response.data.errors) {
        // Handle validation errors
        const validationErrors = error.response.data.errors;

        // Use setError to assign server errors to form fields
        Object.keys(validationErrors).forEach((field) => {
          setError(field, {
            type: "server",
            message: validationErrors[field].join(", "), // Combine multiple error messages if any
          });
        });
      } else {
        console.error("Error submitting form:", error);
        alert(error.response.data.errors || 'Error adding form')
      }
    } finally {
      setLoading(false); // Turn off loading after form submission
    }
  };





  return (
    <div>
      <div className=" w-full bg-white">
        <Link to={`/${ADMIN_URL_PATH}/adminpanel`} className=" block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm"><IconArrowLeft /></Link>

      </div>
      <div className="w-full min-h-screen flex justify-center items-center p-8 overflow-auto">
        <div className="w-full max-w-4xl ">
          <h1 className="text-3xl font-semibold text-green-900 mb-10">
            Student Creation
          </h1>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
          >
            <Controller
              name="M05_UserName"
              control={control}
              rules={{ required: "User Name is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_UserName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    User Name
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M05_UserName"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="Ebraham Koshi"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_UserCode"
              control={control}
              rules={{ required: "User Code is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_UserCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    User Code
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M05_UserCode"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="##001"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_CountryName"
              control={control}
              rules={{ required: "Country Name is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_CountryName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country Name
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M05_CountryName"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="India"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />


            <Controller
              name="M05_DOB"
              control={control}
              rules={{ required: "Date of Birth is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_DOB"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date of Birth
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="date"
                    id="M05_DOB"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_M02_BachId"
              control={control}
              rules={{ required: "Batch ID is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_M02_BachId"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    Batch ID
                  </label>
                  <Select
                    {...field}
                    options={batches}
                    placeholder="Select Batch"
                    styles={customStyles}
                    className={`react-select-container ${error ? "error-border" : ""
                      }`}
                    classNamePrefix="react-select"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />
            <Controller
              name="M05_Gender"
              control={control}
              rules={{ required: "Gender is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_Gender"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Gender
                  </label>
                  <select
                    {...field}
                    id="M05_Gender"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_Qualification"
              control={control}
              rules={{ required: "Qualification is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_Qualification"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Qualification
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M05_Qualification"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="Plus Two"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_MobileNumber"
              control={control}
              rules={{
                required: "Mobile Number is required",
                pattern: {
                  value: /^\+?[1-9]\d{1,14}$/,
                  message: "Invalid phone number",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_MobileNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobile Number
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="tel"
                    id="M05_MobileNumber"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="+91 1234567890"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_Email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_Email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="email"
                    id="M05_Email"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="Ebraham Koshi"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            {/* <Controller
            name="M05_LoginUserName"
            control={control}
            rules={{ required: "Login Username is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M05_LoginUserName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Login Username
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_LoginUserName"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Username"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          /> */}

            <Controller
              name="M05_Password"
              control={control}
              rules={{
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_Password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="password"
                    id="M05_Password"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="min 8 letters"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_JoiningDate"
              control={control}
              rules={{ required: "Joining Date is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_JoiningDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Joining Date
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="date"
                    id="M05_JoiningDate"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            {/* <Controller
            name="M05_TerminationDate"
            control={control}
            render={({ field }) => (
              <div>
                <label
                  htmlFor="M05_TerminationDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Termination Date
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="date"
                  id="M05_TerminationDate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
              </div>
            )}
          /> */}

            {/* <Controller
            name="M05_BloodGroup"
            control={control}
            rules={{ required: "Blood Group is required" }}
            render={({ field, fieldState: { error } }) => (
              <div>
                <label
                  htmlFor="M05_BloodGroup"
                  className="block text-sm font-medium text-gray-700"
                >
                  Blood Group
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M05_BloodGroup"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          /> */}

            <Controller
              name="M05_M04_Role"
              control={control}
              rules={{ required: "At least one role is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-2 mb-4 sm:mb-0">
                  <label
                    htmlFor="M05_M04_Role"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Roles
                  </label>
                  <Select
                    {...field}
                    options={roles}
                    isMulti
                    isDisabled={loading}
                    placeholder="Select Roles"
                    styles={customStyles}
                    className={`react-select-container ${error ? "error-border" : ""
                      }`}
                    classNamePrefix="react-select"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />
            <Controller
              name="M07_M11_DivisionId"
              control={control}
              rules={{ required: "Division is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M07_M11_DivisionId"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Division
                  </label>
                  <select
                    {...field}
                    id="M07_M11_DivisionId"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    <option value="">Select Division</option>
                    {division.map(
                      (divs)=>{
                        return <option key={divs.value} value={divs.value}>{divs.label}</option>
                      }
                    )}
                  </select>
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_Address"

              control={control}
              rules={{ required: "Address is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-2 mb-4 sm:mb-4">
                  <label
                    htmlFor="M05_Address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <textarea
                    {...field}
                    disabled={loading}
                    id="M05_Address"
                    rows="1"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  ></textarea>
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="M05_AllowLogin"
              control={control}
              render={({ field }) => (
                <div className="flex items-center mb-4 sm:mb-0">
                  <input
                    {...field}
                    disabled={loading}
                    type="checkbox"
                    id="M05_AllowLogin"
                    className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                  />
                  <label
                    htmlFor="M05_AllowLogin"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Allow Login
                  </label>
                </div>
              )}
            />

            <Controller
              name="M05_IsActive"
              control={control}
              render={({ field }) => (
                <div className="flex items-center mb-4 sm:mb-0">
                  <input
                    {...field}
                    disabled={loading}
                    type="checkbox"
                    id="M05_IsActive"
                    className="rounded border-gray-300 text-green-600 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                  />
                  <label
                    htmlFor="M05_IsActive"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Is Active
                  </label>
                </div>
              )}
            />

            <div className="col-span-full">
              <button
                disabled={loading}
                type="submit"
                className="w-full py-2 px-4 flex gap-2 justify-center bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                {loading && <IconLoader className="animate-spin" />}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

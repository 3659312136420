import React, { useEffect, useState } from 'react'
import api from '../../components/adminAxios'
import { useNavigate, useParams } from 'react-router-dom';
function RoleEdit() {
    const navigate = useNavigate()
    const { id } = useParams()

    const [formData, setFormData] = useState({
        name: '', 
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await api.get(`/new_role/${id}`);
            console.log(response.data.data, '--------hjk');
            setFormData({
                name: response.data.data.name || '',
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.put(`/new_role/${id}`, formData);
            console.log('Course updated:------------', response.data.success);
            if(response.data.success === true){
                navigate('/role')
            }
        } catch (error) {
            console.log('Error updating course:', error);
        }
    };

    return (
        <div>
          <div style={{ backgroundColor: '#dff6ec', width: '100%', height: '100vh' }} className='w-full min-h-screen flex justify-center items-center p-8 overflow-auto'>
            <div className="form-container w-full max-w-4xl text-center">
                <h1  className="text-3xl font-semibold text-green-900 mb-10">Edit Role</h1>
                <form onSubmit={handleSubmit}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="mb-4 sm:mb-0">
                            <label style={{textAlign:'start'}} className='block text-sm font-medium text-gray-700'>TITLE</label>
                            <input
                                style={{ width: '435px' }}
                                className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500'
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-span-full mt-5 flex justify-center">
                        <button
                            style={{ width: '440px' }}
                            type="submit"
                            className="py-2 px-4 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            UPDATE
                        </button>
                    </div>
                </form>
            </div>
        </div>

        </div>
  )
}

export default RoleEdit
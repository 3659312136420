import React, { useState } from 'react'
import { OffcanvasComponent } from '../../components/offcanvas'
import api from '../../components/adminAxios';
import { useNavigate } from 'react-router-dom';

function Role() {
  const navigate = useNavigate()
    const [formData, setFormData] = useState({
        name: '', 
      });

  const [errors, setErrors] = useState({});


  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!formData.name.trim()) {
      formErrors.name = 'Title is required';
    }
    
    if (Object.keys(formErrors).length === 0) {
      console.log('Form submitted:', formData);
    } else {
      setErrors(formErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const handleOnSubmit = async () =>{
    try {
        const response = await api.post('/new_role',formData)
        console.log(response,"-----------------------------------");
        navigate('/role')
    } catch (error) {
        console.log(error);
        
    }
  }

  return (
    <div>
    
  
    <div 
      style={{ 
        backgroundColor: '#dff6ec', 
        width: '100%', 
        height: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center' // Changed to alignItems for vertical centering
      }} 
      className='w-full min-h-screen p-8 overflow-auto'
    >
      <div 
        className="form-container w-full max-w-4xl"
        style={{
          display: 'flex', 
          flexDirection: 'column', // Ensure content is stacked vertically
          justifyContent: 'center', 
          alignItems: 'center' // Horizontal centering for the form elements
        }}
      >
        <h1 style={{textAlign:'start'}} className="text-3xl font-semibold text-green-900 mb-10">Role Creation</h1>
  
        <form onSubmit={handleSubmit}>
          <div className="mb-4 sm:mb-0">
            <label className='block text-sm font-medium text-gray-700'>Name</label>
            <input
              style={{ width: '435px' }}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500'
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span className="error-text text-red-600">{errors.name}</span>}
          </div>
          
          <div className="col-span-full mt-5">
            <button
              style={{ width: '435px' }}
              type="submit"
              onClick={handleOnSubmit}
              className="w-full py-2 px-4 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  

  )
}

export default Role

import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

export function Success() {
  const counters = useRef([]);
  const successSectionRef = useRef(null);
  const hasAnimated = useRef(false);

  useEffect(() => {
    const counts = [15, 89, 35, 25, 15]; // The final numbers to reach

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !hasAnimated.current) {
          hasAnimated.current = true; // Prevent re-animation
          
          counters.current.forEach((counter, index) => {
            gsap.fromTo(
              counter,
              { innerText: 0 }, // Start from 0
              {
                innerText: counts[index], // End at the correct count
                duration: 2.5, // Duration of 3 seconds for the animation
                ease: 'power1.out',
                snap: { innerText: 1 }, // Snap to whole numbers
                onUpdate() {
                  // Format the final value after animation based on the index
                  let value = Math.floor(counter.innerText);
                  if (index === 0) {
                    counter.innerText = `${value}K+`; // Add "K+" for the first counter
                  } else if ([1].includes(index)) {
                    counter.innerText = `${value}%`; // Add "+" for the 4th and 5th counters
                  } else if ([3, 4].includes(index)) {
                    counter.innerText = `${value}+`; // Add "+" for the 4th and 5th counters
                  } else {
                    counter.innerText = value; // No extra symbol for the rest
                  }
                },
              }
            );
          });
        }
      },
      { threshold: 0.3 } // Trigger when 30% of the section is visible
    );

    if (successSectionRef.current) {
      observer.observe(successSectionRef.current);
    }

    return () => {
      if (successSectionRef.current) {
        observer.unobserve(successSectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={successSectionRef} className="w-full flex flex-col gap-6 mt-10 mb-20 lg:px-28 md:px-10 px-5">
      <h1 className="md:text-4xl text-2xl md:font-bold font-semibold">Our Success</h1>
      <div className="w-full flex bg-gray-200 rounded-lg">
        <div className="w-[20%] md:py-10 py-2">
          <h1 ref={el => (counters.current[0] = el)} style={{ fontSize: 'clamp(15px, 3vw, 36px)' }} className="font-bold">
            15K+
          </h1>
          <p style={{ fontSize: 'clamp(8px, 1.2vw, 14px)' }}>Number Of Students</p>
        </div>
        <div className="w-[20%] md:py-10 py-2 bg-white border-y-2 border-gray-200">
          <h1 ref={el => (counters.current[1] = el)} style={{ fontSize: 'clamp(15px, 3vw, 36px)' }} className="font-bold">
            75%
          </h1>
          <p style={{ fontSize: 'clamp(8px, 1.2vw, 14px)' }}>Percentage Of Success</p>
        </div>
        <div className="w-[20%] md:py-10 py-2">
          <h1 ref={el => (counters.current[2] = el)} style={{ fontSize: 'clamp(15px, 3vw, 36px)' }} className="font-bold">
            35
          </h1>
          <p style={{ fontSize: 'clamp(8px, 1.2vw, 14px)' }}>Number Of Questions</p>
        </div>
        <div className="w-[20%] md:py-10 py-2 bg-white border-y-2 border-gray-200">
          <h1 ref={el => (counters.current[3] = el)} style={{ fontSize: 'clamp(15px, 3vw, 36px)' }} className="font-bold">
            25+
          </h1>
          <p style={{ fontSize: 'clamp(8px, 1.2vw, 14px)' }}>Number Of Experts</p>
        </div>
        <div className="w-[20%] md:py-10 py-2">
          <h1 ref={el => (counters.current[4] = el)} style={{ fontSize: 'clamp(15px, 3vw, 36px)' }} className="font-bold">
            15+
          </h1>
          <p style={{ fontSize: 'clamp(8px, 1.2vw, 14px)' }}>Year Of Experience</p>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import api from "../../../components/adminAxios";
import { OffcanvasComponent } from "../../../components/offcanvas";
import { Link, useNavigate } from "react-router-dom";
import { IconArrowLeft, IconLoader } from "@tabler/icons-react";
import { ADMIN_URL_PATH } from "../../../App";


export function DivisionAdd() {
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [batches, setBatches] = useState([]);
  const [Mentor, setMentor] = useState([]);
  const [loading, setLoading] = useState(false);


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #dee2e6",
      borderColor: state.isFocused
        ? "#2684FF"
        : errors[state.selectProps.name]
          ? "#ff000080" // Red border for errors
          : "#cccccc",
      "&:hover": {
        borderColor: state.isFocused
          ? "#2684FF"
          : errors[state.selectProps.name]
            ? "#ff000080" // Red border for errors
            : "#cccccc",
      },
      boxShadow: errors[state.selectProps.name]
        ? "0 0 0 1px red"
        : provided.boxShadow,
    }),
  };

  useEffect(() => {
    fetchTeacher();
    fetchBatches()
  }, []);

  const fetchTeacher = async () => {
    try {
      const response = await api.get(
        "https://elearningbackend.prosevo.com/api/teachers"
      );
      const filteredRoles = response.data.data.map((role) => ({
        value: role.M05_UserId,
        label: role.M05_UserName,
      }));
      console.log(filteredRoles)
      setMentor(filteredRoles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };


  const fetchBatches = async () => {
    try {
      const response = await api.get('batch_ids');
      const batchData = response.data.data;
      const formattedBatches = Object.entries(batchData).map(([label, value]) => ({
        value: value,
        label: label
      }));
      setBatches(formattedBatches);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true)
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "M11_Batch_id"||key === 'M11_M05_User_Id') {
        console.log(data[key])
        formData.append(key, data[key].value);
      } else if (key === "M05_AllowLogin" || key === "M05_IsActive") {
        formData.append(key, data[key] ? 1 : 0);
      } else {
        formData.append(key, data[key]);
      }
    });

    try {
      const response = await api.post("/division", formData);
      navigate(`/${ADMIN_URL_PATH}/division`);
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      if (error.response && error.response.status === 422 && error.response.data.errors) {
        // Handle validation errors
        const validationErrors = error.response.data.errors;

        // Use setError to assign server errors to form fields
        Object.keys(validationErrors).forEach((field) => {
          setError(field, {
            type: "server",
            message: validationErrors[field].join(", "), // Combine multiple error messages if any
          });
        });
      } else {
        console.error("Error submitting form:", error);
        alert(error.response.data.errors || 'Error adding form')
      }
    } finally {
      setLoading(false); // Turn off loading after form submission
    }
  };

  return (
    <div>
      <div className=" w-full bg-white">
        <Link to={`/${ADMIN_URL_PATH}/division`} className=" block w-10 px-5 py-5 hover:bg-gray-500/10 rounded-sm"><IconArrowLeft /></Link>

      </div>
      <div style={{ backgroundColor: '#dff7ed' }} className="w-full min-h-screen flex justify-center items-center p-8 mb-16 overflow-auto">
        <div className="w-full max-w-4xl ">
          <h1 className="text-3xl font-semibold text-green-900 mb-10">
            Division Creation
          </h1>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
          >
            <Controller
              name="M11_Division_name"
              control={control}
              rules={{ required: "Division Name is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M11_Division_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Division Name
                  </label>
                  <input
                    {...field}
                    disabled={loading}
                    type="text"
                    id="M11_Division_name"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    placeholder="Ebraham Koshi"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />


            <Controller
              name="M11_Batch_id"
              control={control}
              rules={{ required: "Batch ID is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M11_Batch_id"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    Batch ID
                  </label>
                  <Select
                    {...field}
                    options={batches}
                    placeholder="Select Batch"
                    styles={customStyles}
                    className={`react-select-container ${error ? "error-border" : ""
                      }`}
                    classNamePrefix="react-select"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />
            <Controller
              name="M11_M05_User_Id"
              control={control}
              rules={{ required: "Batch ID is required" }}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4 sm:mb-0">
                  <label
                    htmlFor="M11_M05_User_Id"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    Mentor
                  </label>
                  <Select
                    {...field}
                    options={Mentor}
                    placeholder="Select Mentor"
                    styles={customStyles}
                    className={`react-select-container ${error ? "error-border" : ""
                      }`}
                    classNamePrefix="react-select"
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-600">{error.message}</p>
                  )}
                </div>
              )}
            />




            <div className="col-span-full">
              <button
                disabled={loading}
                type="submit"
                className="w-full py-2 px-4 flex gap-2 justify-center bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                {loading && <IconLoader className="animate-spin" />}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}



import React from 'react'
import AdminLogin from '../../components/adminLogin'

export default function AdminPage() {
  return (
    <div>
      <div className='bg-green-100  min-h-screen   flex justify-center items-center flex-col overflow-auto'>
        <AdminLogin />
      </div>
    </div>
  )
}

import React from "react";
import { ButtonEdge } from "../../../components/buttonsOg";

function CourceCategoryCard({ title, content, titleImage }) {
  return (
    <article className="flex flex-col text-center rounded-none min-w-[290px] max-w-[350px] relative z-10 ">
      <div className="flex flex-col items-center px-4 pt-3 pb-7  w-full bg-white rounded-2xl shadow-md">
        <img
          loading="lazy"
          src={titleImage}
          alt={title}
          className="object-contain w-20 aspect-square"
        />
        <h2 className="mt-3 text-md font-semibold text-pumpkinToast-500">
          {title}
        </h2>
        <p className="self-stretch mt-6  text-md font-medium leading-7 text-gray-500 mb-3 h-[130px]">
          {content}
        </p>
        <ButtonEdge variant={"secondary"} text="Explore Course"/>
      </div>
    </article>
  );
}

export  {CourceCategoryCard};

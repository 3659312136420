import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../../components/adminAxios";
import { useNavigate, useParams } from "react-router-dom";
import { OffcanvasComponent } from "../../../components/offcanvas";
import { Controller, useForm } from "react-hook-form";
import { IconLoader } from "@tabler/icons-react";
import { ADMIN_URL_PATH } from "../../../App";

export function DivisionEdit() {
  const { id } = useParams();
  console.log(id, 'id');

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [batches, setBatches] = useState([]);

  const [studentdatas, setStudentData] = useState('')
  const [Mentor, setMentor] = useState([]);

  console.log(studentdatas, 'fdfdfdfdfdf');


  const [roles, setRoles] = useState([]);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      M11_Batch_id: '1',
      M11_Division_name: '',
      M11_Mentor_name: ''
    },
  });

  useEffect(() => {
    fetchBatches()
    fetchTeacher();
    getStudent();

  }, []);

  const fetchTeacher = async () => {
    try {
      const response = await api.get(
        "https://elearningbackend.prosevo.com/api/teachers"
      );
      const filteredRoles = response.data.data.map((role) => ({
        value: role.M05_UserId,
        label: role.M05_UserName,
      }));
      console.log(filteredRoles)
      setMentor(filteredRoles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const fetchBatches = async () => {
    try {
      const response = await api.get('batch_ids');
      const batchData = response.data.data;
      const formattedBatches = Object.entries(batchData).map(([label, value]) => ({
        value: value,
        label: label
      }));
      setBatches(formattedBatches);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const getStudent = async () => {
    try {
      const response = await api.get(`/division/${id}`);
      const studentData = response.data.data;
      console.log(studentData)
      reset({
        M11_Batch_id: studentData.batch.M02_BatchId || '',
        M11_Division_name: studentData.M11_Division_name,
        M11_M05_User_Id: studentData.mentor?.M11_M05_User_Id
      });
    } catch (error) {
      console.log(error);
    }
  };




  const onSubmit = async (formData) => {
    setLoading(true)
    try {
      console.log('Submitting form data:', formData);
      // formData.M11_M05_User_Id = formData.M11_M05_User_Id.value
      // formData.M11_M05_User_Id = formData.M11_M05_User_Id.value
      const response = await api.put(`/division/${id}`, formData);
      console.log('Update response:', response);
      navigate(`/${ADMIN_URL_PATH}/division`);
    } catch (error) {
      if (error.response && error.response.status === 422 && error.response.data.errors) {
        // Handle validation errors
        const validationErrors = error.response.data.errors;

        // Use setError to assign server errors to form fields
        Object.keys(validationErrors).forEach((field) => {
          setError(field, {
            type: "server",
            message: validationErrors[field].join(", "), // Combine multiple error messages if any
          });
        });
      } else {
        console.error("Error submitting form:", error);
        alert(error.response.data.errors || 'Error Updating sutdent')
      }
    }
    setLoading(false)

  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #dee2e6",
      borderColor: state.isFocused
        ? "#2684FF"
        : errors[state.selectProps.name]
          ? "#ff000080" // Red border for errors
          : "#cccccc",
      "&:hover": {
        borderColor: state.isFocused
          ? "#2684FF"
          : errors[state.selectProps.name]
            ? "#ff000080" // Red border for errors
            : "#cccccc",
      },
      boxShadow: errors[state.selectProps.name]
        ? "0 0 0 1px red"
        : provided.boxShadow,
    }),
  };


  return (
    <div style={{ backgroundColor: '#dff7ed' }} className="w-full min-h-screen flex justify-center items-center p-8 overflow-auto">


      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-semibold text-green-900 mb-10">
          Edit Division Detail's
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid md:grid-cols-2  gap-4 "
        >
          <Controller
            name="M11_Division_name"
            control={control}
            rules={{ required: "Division Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M11_Division_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Division Name
                </label>
                <input
                  {...field}
                  disabled={loading}
                  type="text"
                  id="M11_Division_name"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  placeholder="Ebraham Koshi"
                />
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />


          <Controller
            name="M11_Batch_id"
            control={control}
            rules={{ required: "Batch ID is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M11_Batch_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Batch ID
                </label>
                {/* <Select
                  {...field}
                  id="M11_Batch_id"
                  options={batches}
                  placeholder="Select Batch"
                  styles={customStyles}
                  className={`react-select-container ${error ? "error-border" : ""
                    }`}
                  classNamePrefix="react-select"
                /> */}
                <select
                  {...field}
                  id="M11_Batch_id"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                >
                  <option value="">Select BatchId</option>
                  {batches?.map(
                    (divs) => {
                      return <option key={divs.value} value={divs.value}>{divs.label}</option>
                    }
                  )}
                </select>
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />

          <Controller
            name="M11_M05_User_Id"
            control={control}
            rules={{ required: "Batch ID is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="mb-4 sm:mb-0">
                <label
                  htmlFor="M11_M05_User_Id"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                >
                  Mentor
                </label>
                <select
                  {...field}
                  id="M11_M05_User_Id"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                >
                  <option value="">Select Mentor</option>
                  {Mentor?.map(
                    (divs) => {
                      return <option key={divs.value} value={divs.value}>{divs.label}</option>
                    }
                  )}
                </select>
                {/* <Select
                  {...field}
                  options={Mentor}
                  placeholder="Select Mentor"
                  styles={customStyles}
                  className={`react-select-container ${error ? "error-border" : ""
                    }`}
                  classNamePrefix="react-select"
                /> */}
                {error && (
                  <p className="mt-1 text-sm text-red-600">{error.message}</p>
                )}
              </div>
            )}
          />


          <div className="col-span-full">
            <button
              disabled={loading}
              type="submit"
              className="w-full py-2 px-4 flex gap-2 justify-center bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {loading && <IconLoader className="animate-spin" />}
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}


import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'
import React, { useState } from 'react'

export function EventsModal({ elements }) {
    const [currentIndex, setCurrentIndex] = useState(0)

    const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % elements.length)
    }

    const handlePrevious = () => {
        setCurrentIndex((currentIndex - 1 + elements.length) % elements.length)
    }

    return (
        <div className="">
            <div className=" p-4 py-10 relative">
                {/* contents */}

                <div className=' flex flex-col gap-y-10 md:flex-row  w-full min-h-10  items-center '>
                    {/* title */}
                    <h2 className=' md:basis-[30%] font-medium md:text-3xl text-2xl text-pumpkinToast-500'>{elements[currentIndex].title}</h2>
                    {/* image */}
                    <div className=' md:min-w-[300px] md:max-w-[300px] flex justify-center '>
                        <img className='h-80' src={elements[currentIndex].image} alt="" />
                    </div>
                    {/* content */}
                    <p className=' min-h-[150px] max-h-[150px] md:min-h-0 md:max-h-[1000px] md:basis-[30%]'>{elements[currentIndex].content}</p>
                </div>

                <div className="flex justify-center items-center mt-8">
                    <button
                        className="bg-white p-2 rounded-full hover:bg-gray-200"
                        onClick={handlePrevious}
                    >
                        <IconArrowLeft size={20}/>
                    </button>
                    <div className="flex items-center mx-4">
                        {elements.map((_, index) => (
                            <button
                                key={index}
                                className={` rounded-full ${index === currentIndex ? 'bg-gray-500 w-3 h-3' : 'bg-gray-300 w-2 h-2'
                                    } mx-1`}
                                onClick={() => setCurrentIndex(index)}
                            />
                        ))}
                    </div>
                    <button
                        className="bg-white p-2 rounded-full hover:bg-gray-200"
                        onClick={handleNext}
                    >
                        <IconArrowRight size={20}/>
                    </button>
                </div>
            </div>
        </div>
    )
}


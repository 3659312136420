import React from "react";
import { ButtonEdge } from "../../components/buttonsOg";

const benefitItems = [
  {
    icon: "/assets/temp/image1.png",
    text: "Teachers don't get lost in the grid view and have a dedicated Podium space.",
  },
  {
    icon: "/assets/temp/image1.png",
    text: "TA's and presenters can be moved to the front of the class.",
  },
  {
    icon: "/assets/temp/image1.png",
    text: "Teachers can easily see all students and class data at one time.",
  },
];

function GetIdCard() {
  return (
    <section className="  flex items-center justify-center">
      <div className=" flex flex-col-reverse lg:flex-row items-center lg:items-end  gap-10 w-[70vw] max-w-[1400px] mx-auto my-7  ">
        <div className="basis-1/2  " >
          <div className="flex items-start mb-5">
            <ButtonEdge className=" w-min my-auto" text="Benefits" variant={'grayButton'} />
          </div>
          <p className="font-bold text-pumpkinToast-500 text-start pb-6" style={{fontSize:'clamp(28px, 3vw, 45px)'}}>Get Student ID card</p>
          <div className=" flex flex-col gap-5">
            {
              benefitItems.map(({ icon, text }) => {
                return (
                  <div className=" flex gap-9 items-center">
                    <img className=" rounded-full shadow-xl h-11 w-11" src={icon} alt="" />
                    <p className=" text-gray-500 text-sm text-start">{text}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="  ">
          <div className=" bg-pumpkinToast-500/20 grow flex rounded-lg px-14 py-5  gap-2">
            <img  width={'50%'} src="/assets/temp/idCard.png" alt="" />
            <img width={'50%'} src="/assets/temp/idCard.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export { GetIdCard };
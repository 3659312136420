import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { IconHome, IconHomeCog, IconBook, IconSchool, IconBrandGooglePhotos, IconAddressBook } from '@tabler/icons-react';

export function NavBar() {
  const [openSidebar, setOpenSidebar] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const closeSidebar = () => {
    setOpenSidebar(false);
  };

  const links = [
    { name: 'Home', path: 'home', icon: <IconHome className='h-5 w-5' /> },
    { name: 'About', path: 'about', icon: <IconHomeCog className='h-5 w-5' /> },
    { name: 'Library', path: 'library', icon: <IconBook className='h-5 w-5' /> },
    { name: 'Courses', path: 'courses', icon: <IconSchool className='h-5 w-5' /> },
    { name: 'Gallery', path: 'gallery', icon: <IconBrandGooglePhotos className='h-5 w-5' /> },
    { name: 'Contact', path: 'contact', icon: <IconAddressBook className='h-5 w-5' /> },
  ];

  return (
    <>
      {/* Navbar for screens above 700px */}
      <div className='hidden sm:flex w-full h-[60px] bg-white justify-between lg:px-28 md:px-10 px-5 items-center fixed top-0 z-40 shadow-lg'>
        <div className="logo font-semibold text-2xl flex gap-1 items-end">
          <img src="/assets/darulLogo.png" alt="logo" className='w-[50px] h-[50px]' />
          <p className='pb-1'>DarulHadees</p>
        </div>
        <div className='flex lg:gap-10 gap-4 md:gap-5'>
          {links.map(({ name, path }) => (
            <NavLink
              key={path}
              to={`/${path}`}
              className={({ isActive }) => (isActive ? 'text-orange-500' : '')}
            >
              <p>{name}</p>
            </NavLink>
          ))}
        </div>
      </div>

      {/* Sidebar and Menu Icon for screens below 700px */}
      <div className="flex sm:hidden fixed top-0 w-full h-[60px] bg-white items-center justify-between px-5 z-40 shadow-lg">
        <div className="logo font-semibold text-2xl flex gap-1 items-end">
          <img src="/assets/darulLogo.png" alt="logo" className='w-[50px] h-[50px]' />
          <p className='text-xl pb-2'>DarulHadees</p>
        </div>
        <button onClick={toggleSidebar} style={{ backgroundColor: '#DD9356' }} className="p-2 rounded">
          <Bars3Icon className="h-6 w-6 text-white" />
        </button>
      </div>

      {/* Custom Sidebar */}
      <div className={`fixed top-0 left-0 h-full w-[250px] bg-white shadow-lg z-50 transform ${openSidebar ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <div className="flex flex-col gap-5 px-7 py-3">
          {/* Logo Section */}
          <div className="flex items-end gap-2">
            <img src="/assets/darulLogo.png" alt="logo" className='w-[40px] h-[40px]' />
            <p className="font-semibold text-xl">DarulHadees</p>
          </div>

          {/* Links Section */}
          <div className="flex flex-col gap-5">
            {links.map(({ name, path, icon }) => (
              <NavLink
                key={path}
                to={`/${path}`}
                className={({ isActive }) => (isActive ? 'text-white rounded-md bg-pumpkinToast-500' : 'text-black')}
                onClick={closeSidebar}
              >
                <div className="flex items-center gap-3 w-full h-[40px] px-4">
                  {icon}
                  <p>{name}</p>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      </div>

      {/* Backdrop Blur */}
      {openSidebar && (
        <div
          onClick={closeSidebar}
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-md z-40 transition-opacity duration-300 ease-in-out"
        />
      )}
    </>
  );
}

import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

export function Managment() {
  const cardsRef = useRef([]);

  useEffect(() => {
    // Loop over the card elements and apply the scroll-based animation
    cardsRef.current.forEach((card) => {
      gsap.fromTo(
        card,
        {
          y: 100, // Start 100px below
          opacity: 0,
        },
        {
          y: 0, // Move to original position
          opacity: 1,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: card, // Trigger the animation on this element
            start: "top 80%", // Start when top of card hits 80% of viewport
            end: "center center", // End when the card is at the center of the window
            scrub: 1, // Scrub smooths the animation based on scroll position
            toggleActions: "play none none reverse", // Optionally reverse when scrolling back up
          },
        }
      );
    });
  }, []);

  return (
    <div className="w-full text-left lg:px-28 md:px-10 px-5 relative lg:py-24 py-16">
      <div className='bg-[rgba(255,200,100,1)] mt-[60px] w-[300px] h-[300px] absolute blur-[160px] z-0 top-[-100px] right-0'></div>
      <h5 className="text-pumpkinToast-500 z-10 relative">Practice Advice</h5>
      <h1 style={{fontSize:'clamp(25px, 3vw, 36px)'}} className="z-10 relative">Our Management</h1>
      <p className="mt-2 z-10 relative" style={{ fontSize: 'clamp(13px, 1.4vw, 16px)' }}>
        Problems trying to resolve the conflict between the two major realms of Classical physics: Newtonian mechanics{" "}
      </p>

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 z-10">
        <div
          className="card bg-gray-100 rounded-xl overflow-hidden z-10"
          ref={el => (cardsRef.current[0] = el)}
        >
          <div className="w-full">
            <img
              src="https://aecagra.edu.in/wp-content/uploads/2023/05/chairman-sir-1024x717-1-e1685096050995.jpg"
              alt=""
              className="h-full w-full"
            />
          </div>
          <div className=" flex flex-col justify-center items-center md:gap-2 gap-0 p-5">
            <h2 className="text-lg font-bold">Abdul Majeed P</h2>
            <h3 className="text-md font-bold text-gray-400">Chairman</h3>
            <p className="mt-3 text-center">
              “ Is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy”
            </p>
          </div>
        </div>

        <div
          className="card bg-gray-100 rounded-xl overflow-hidden z-10"
          ref={el => (cardsRef.current[1] = el)}
        >
          <div className="w-full">
            <img
              src="https://itsengg.edu.in/chairman-1.png"
              alt=""
              className="h-full w-full"
            />
          </div>
          <div className=" flex flex-col justify-center items-center md:gap-2 gap-0 p-5">
            <h2 className="text-lg font-bold">Kuryan Pk</h2>
            <h3 className="text-md font-bold text-gray-400">Manager</h3>
            <p className="mt-3 text-center">
              “ Is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy”
            </p>
          </div>
        </div>

        <div
          className="card bg-gray-100 rounded-xl overflow-hidden z-10"
          ref={el => (cardsRef.current[2] = el)}
        >
          <div className="w-full">
            <img
              src="https://www.k12digest.com/wp-content/uploads/2020/09/Pallavi-Upadhyaya-PrincipalDPS-RNE-Ghaziabad-1_800x480-550x330.jpg"
              alt=""
              className="h-full w-full"
            />
          </div>
          <div className=" flex flex-col justify-center items-center md:gap-2 gap-0 p-5">
            <h2 className="text-lg font-bold">Shilpa Thomas</h2>
            <h3 className="text-md font-bold text-gray-400">Principal</h3>
            <p className="mt-3 text-center">
              “ Is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

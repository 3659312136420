import React, { useEffect, useState } from 'react'
import api from '../../../components/adminAxios';
import { useNavigate } from 'react-router-dom';
import { IconLoader } from '@tabler/icons-react';
import { ADMIN_URL_PATH } from '../../../App';

function ClassView() {
  const navigate = useNavigate();
  const [classes, setClasses] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  console.log(classes, 'classes');

  const findData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/classes')
      setClasses(response.data.data, 'response')
      console.log(response.data.data, 'response');
    } catch (error) {
      console.log(error)
      alert(error.response?.data?.message || 'error loading data');
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteModalToggle = (classItem) => {
    setSelectedClass(classItem);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }

  const handleDelete = async () => {
    if (!selectedClass) return;
    setIsDeleteLoading(true);
    try {
      await api.delete(`classes/${selectedClass.M03_ClassesId}`);
      setIsDeleteModalOpen(false);
      alert(`Class "${selectedClass.M03_Title}" has been deleted successfully.`);
      findData(); // Refresh the class list
    } catch (error) {
      console.log(error);
      alert('Error deleting class. Please try again.');
    } finally {
      setIsDeleteLoading(false);
    }
  }

  const handleOnclick = async (id) => {
    try {
      navigate(`/${ADMIN_URL_PATH}/class_edit/${id}`)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    findData()
  }, [])

  return (
    <div style={{ width: '100%', backgroundColor: '#dff7ed', height: '100vh', overflowY: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          onClick={() => navigate(`/${ADMIN_URL_PATH}/class_add`)}
          className='btn btninner'
          style={{ fontFamily: 'fantasy', color: 'white', margin: '20px', width: '100%' }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
            <path fillRule="evenodd" d="M17.663 3.118c.225.015.45.032.673.05C19.876 3.298 21 4.604 21 6.109v9.642a3 3 0 0 1-3 3V16.5c0-5.922-4.576-10.775-10.384-11.217.324-1.132 1.3-2.01 2.548-2.114.224-.019.448-.036.673-.051A3 3 0 0 1 13.5 1.5H15a3 3 0 0 1 2.663 1.618ZM12 4.5A1.5 1.5 0 0 1 13.5 3H15a1.5 1.5 0 0 1 1.5 1.5H12Z" clipRule="evenodd" />
            <path d="M3 8.625c0-1.036.84-1.875 1.875-1.875h.375A3.75 3.75 0 0 1 9 10.5v1.875c0 1.036.84 1.875 1.875 1.875h1.875A3.75 3.75 0 0 1 16.5 18v2.625c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625v-12Z" />
            <path d="M10.5 10.5a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963 5.23 5.23 0 0 0-3.434-1.279h-1.875a.375.375 0 0 1-.375-.375V10.5Z" />
          </svg>
          ADD CLASS
        </button>
      </div>

      <div style={{ boxShadow: '0 4px 8px rgba(0.2, 0.2, 0.2, 0.6)',  }} className="bg-green-50 sm:rounded-lg text-center overflow-auto box-border mx-6 mb-32 ">
        <table style={{ width: '100%', borderRadius: '30px' }} className=" w-full text-sm text-left rtl:text-right  text-blue-100 dark:text-blue-100 ">
          <thead className="text-xs text-white uppercase bg-green-600 dark:text-white">
            <tr style={{ width: '100% ' }} >
              <th scope="col" className="px-6 py-3">SUBJECT</th>
              <th scope="col" className="px-6 py-3">TITLE</th>
              <th scope="col" className="px-6 py-3">BATCH</th>
              <th scope="col" className="px-6 py-3">SCHEDULED AT</th>
              <th scope="col" className="px-6 py-3">EXPIRE AT</th>
              <th scope="col" className="px-6 py-3">YOUTUBE LINK</th>
              <th scope="col" className="px-6 py-3">EDIT</th>
              <th scope="col" className="px-6 py-3">DELETE</th>
            </tr>
          </thead>
          <tbody>
            {
              isLoading ?
                <tr>
                  <td colSpan="7" className="px-6 py-4 text-center" style={{ fontWeight: 'bold', color: '#8B0000' }}>
                    <IconLoader className=' animate-spin mx-auto' />
                  </td>
                </tr>
                : classes.length > 0 ? (
                  classes.map((classesItem, index) => (
                    <tr style={{ backgroundColor: '#dff6ec' }} key={index} className=" border-b border-green-300 text-green-500 cursor-pointer">
                      <td className="px-6 py-4">
                        {classesItem.M03_SubjectName}
                      </td>
                      <td className="px-6 py-4">
                        {classesItem.M03_Title}
                      </td>
                      <td className="px-6 py-4">
                        {classesItem.batch.M02_Name}
                      </td>
                      <td className="px-6 py-4">
                        {classesItem.M03_ScheduledAt}
                      </td>
                      <td className="px-6 py-4">
                        {classesItem.M03_ExpiryTime}
                      </td>
                      <td className="px-6 py-4">
                        {classesItem.M03_YouTubeLink}
                      </td>
                      <td className="px-6 py-4">
                        <div onClick={() => handleOnclick(classesItem.M03_ClassesId)}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                          </svg>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div onClick={() => handleDeleteModalToggle(classesItem)}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                          </svg>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center" style={{ fontWeight: 'bold', color: '#8B0000' }}>
                      NO COURSES FOUND
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>

      {isDeleteModalOpen && (
        <div id="popup-modal" className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                disabled={isDeleteLoading}
                onClick={() => handleDeleteModalToggle(null)}
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1l6 6m0 0 6-6M7 7l6 6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-6 text-center">
                <svg
                  aria-hidden="true"
                  className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 12l6-6m0 0l6 6m-6-6v12"
                  />
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to delete the class <span className="font-semibold">{selectedClass?.M03_Title}</span>?
                </h3>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2"
                >
                  {isDeleteLoading ? 'Deleting...' : 'Yes, Delete'}
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteModalToggle(null)}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  No, Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ClassView
import React, { useEffect, useState } from 'react'
import { OffcanvasComponent } from '../../components/offcanvas'
import api from '../../components/adminAxios';
import { useNavigate } from 'react-router-dom';
import './RoleList.css'

function RoleList() {


    const navigate = useNavigate();
    const [ roles , setroles ] = useState([])

    const getData = async() => {
        try {
            
            const response = await api.get('/new_role')
            console.log(response.data.data,'log');
            setroles(response.data.data);
            

        } catch (error) {
            console.log(error);
        }
    }

    const handleOnclick = async (id) => {
       try {
        navigate(`/role_edit/${id}`)
       } catch (error) {
        console.log(error);
       }
    }

    const handleDelete = async (id) => {
      try {
        const response =  await api.delete(`/new_role/${id}`)  
        console.log(response);
        window.location.reload();
        
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
     getData();
    }, [])


  return (
    <div style={{width: '100%', backgroundColor: '#dff7ed', height: '100vh',}} >
          <div style={{ 
    display: 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center',
  }}>
    <button onClick={()=>navigate('/role_add')} className='btn btninner' style={{ margin: '50px', position: 'absolute', marginTop: '124px', marginRight: '100px',fontFamily:'fantasy',color:'white' }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
          <path fillRule="evenodd" d="M17.663 3.118c.225.015.45.032.673.05C19.876 3.298 21 4.604 21 6.109v9.642a3 3 0 0 1-3 3V16.5c0-5.922-4.576-10.775-10.384-11.217.324-1.132 1.3-2.01 2.548-2.114.224-.019.448-.036.673-.051A3 3 0 0 1 13.5 1.5H15a3 3 0 0 1 2.663 1.618ZM12 4.5A1.5 1.5 0 0 1 13.5 3H15a1.5 1.5 0 0 1 1.5 1.5H12Z" clipRule="evenodd" />
          <path d="M3 8.625c0-1.036.84-1.875 1.875-1.875h.375A3.75 3.75 0 0 1 9 10.5v1.875c0 1.036.84 1.875 1.875 1.875h1.875A3.75 3.75 0 0 1 16.5 18v2.625c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625v-12Z" />
          <path d="M10.5 10.5a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963 5.23 5.23 0 0 0-3.434-1.279h-1.875a.375.375 0 0 1-.375-.375V10.5Z" />
        </svg> ADD
    </button>
  </div>

    <div className='' style={{   display: 'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:'#dff7ed' }}>
    

 

  
    <div style={{ boxShadow: '0 4px 8px rgba(0.2, 0.2, 0.2, 0.6)', padding: '3px' ,width:'60%' ,marginBottom:'82px',marginTop:'15px'}} className="bg-green-50 sm:rounded-lg text-center  ">
    <table style={{width:'100%',borderRadius:'30px'}} className=" w-full text-sm text-left rtl:text-right  text-blue-100 dark:text-blue-100 ">
  <thead  className="text-xs text-white uppercase bg-green-600 dark:text-white">
    <tr style={{width:'100% '}} >
      <th scope="col" className="px-6 py-3">ROLE</th>
      <th scope="col" className="px-6 py-3">EDIT</th>
      <th scope="col" className="px-6 py-3">DELETE</th>
    </tr>
  </thead>
  <tbody>
    {roles.length > 0 ? (
      roles.map((rolesItems, index) => (
        <tr
        
        style={{backgroundColor:'#dff6ec'}} key={index} className=" border-b border-green-300 text-green-500 cursor-pointer">
          <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap dark:text-green-500">
            {rolesItems.M04_RoleName}
          </th>
        


          <td className="px-6 py-4">
            <div
          onClick={() => handleOnclick(rolesItems.M04_RoleId)} >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
        </svg>

            </div>
          </td>


          <td className="px-6 py-4">
            <div
          onClick={() => handleDelete(rolesItems.M04_RoleId)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
          </svg>


            </div>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="7" className="px-6 py-4 text-center" style={{ fontWeight: 'bold', color: '#8B0000' }}>
          NO COURSES FOUND
        </td>
      </tr>
    )}
  </tbody>
</table>
    </div>
  </div>
  </div>
  )
}

export default RoleList
import React, { useState } from 'react';
import { Button, Card, Label, TextInput } from 'flowbite-react';
import { HiMail } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

export default function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigate = useNavigate();

  // const validCredentials = {
  //   email: 'darulhadees@gmail.com',
  //   password: 'darulhadees123'
  // };

  // const handleEmailChange = (e) => {
  //   setEmail(e.target.value);
  //   if (e.target.value === validCredentials.email) {
  //     setEmailError(false);
  //   }
  // };

  // const handlePasswordChange = (e) => {
  //   setPassword(e.target.value);
  //   if (e.target.value === validCredentials.password) {
  //     setPasswordError(false);
  //   }
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   let valid = true;
  //   if (email !== validCredentials.email) {
  //     setEmailError(true);
  //     valid = false;
  //   } else {
  //     setEmailError(false);
  //   }

  const handleSubmit = async(e) =>{
    try {
      e.preventDefault();
      const response = await axios.post('https://elearningbackend.prosevo.com/api/admin/login',{ M05_LoginUserName:email , password:password })
      console.log(response)
      if(response.data.success === true){
        localStorage.setItem('adminToken',response.data.token)
        console.log(response.data.token);
        if(localStorage.getItem('adminToken')){
          navigate(`adminpanel`)
          
        }
      }
      
    } catch (error) {
      console.log(error);
      
    }
  }


  //   if (password !== validCredentials.password) {
  //     setPasswordError(true);
  //     valid = false;
  //   } else {
  //     setPasswordError(false);
  //   }

  //   if (valid) {
  //     navigate('/adminpanel');
  //   }
  // };

  return (
    <div className="flex items-center justify-center h-screen w-[300px] sm:w-[500px]">
      <Card className="max-w-sm w-full">
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="email4" value="Your email" />
            </div>
            <TextInput
              id="email4"
              type="email"
              icon={HiMail}
              placeholder="email@gmail.com"
              color={emailError ? "failure" : 'green'}
              value={email}
              onChange={(e)=>{setEmail(e.target.value)} }
              className={emailError ? 'border-red-500' : ''}
            />
            {emailError && (
              <p className="text-red-500 text-sm mt-1">Invalid email</p>
            )}
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="password1" value="Your password" />
            </div>
            <TextInput
              id="password1"
              type="password"
              color={passwordError ? 'failure' : 'green'}
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
              className={passwordError ? 'border-red-500' : ''}
            />
            {passwordError && (
              <p className="text-red-500 text-sm mt-1">Invalid password</p>
            )}
          </div>
          <Button type="submit" className='bg-green-700 hover:!bg-green-950 '>Submit</Button>
        </form>
      </Card>
    </div>
  );
}

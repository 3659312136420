import React, { useEffect, useState } from 'react';
// import { OffcanvasComponent } from '../../../components/offcanvas';
import api from '../../../components/adminAxios';
import TeacherDetails from './TeacherDetails';
import { useNavigate } from 'react-router-dom';
import { IconLoader } from '@tabler/icons-react';
import { ADMIN_URL_PATH } from '../../../App';

function Teacherlist() {
  const navigate = useNavigate()
  const [teacher, setTeacher] = useState([]);
  const [selectedTeacherId, setSelectedSTeacherId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    const token = localStorage.getItem('adminToken');
    setIsLoading(true);

    try {
      if (!token) {
        throw new Error("NO TOKEN FOUND IN LOCAL STORAGE");
      }
      const response = await api.get('/teachers');
      console.log('helloo', response);

      setTeacher(response.data.data);
    } catch (error) {
      console.log(error);
      alert(error.response?.data?.message || 'error loading data');

    } finally {
      setIsLoading(false);

    }
  };

  const handleOnclick = (id) => {
    console.log(id, 'wht is the id  is the id');

    setSelectedSTeacherId(id);
    setIsModalOpen(true);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    getData();
  }, []);


  return (

    <div style={{ width: '100%', backgroundColor: '#dff7ed', height: '100vh', overflowY: 'auto' }}>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          onClick={() => navigate(`/${ADMIN_URL_PATH}/teacher_add`)}
          className='btn btninner'
          style={{ fontFamily: 'fantasy', color: 'white', margin: '20px', width: '100%' }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
            <path fillRule="evenodd" d="M17.663 3.118c.225.015.45.032.673.05C19.876 3.298 21 4.604 21 6.109v9.642a3 3 0 0 1-3 3V16.5c0-5.922-4.576-10.775-10.384-11.217.324-1.132 1.3-2.01 2.548-2.114.224-.019.448-.036.673-.051A3 3 0 0 1 13.5 1.5H15a3 3 0 0 1 2.663 1.618ZM12 4.5A1.5 1.5 0 0 1 13.5 3H15a1.5 1.5 0 0 1 1.5 1.5H12Z" clipRule="evenodd" />
            <path d="M3 8.625c0-1.036.84-1.875 1.875-1.875h.375A3.75 3.75 0 0 1 9 10.5v1.875c0 1.036.84 1.875 1.875 1.875h1.875A3.75 3.75 0 0 1 16.5 18v2.625c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625v-12Z" />
            <path d="M10.5 10.5a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963 5.23 5.23 0 0 0-3.434-1.279h-1.875a.375.375 0 0 1-.375-.375V10.5Z" />
          </svg>
          ADD TEACHER
        </button>
      </div>

      <div style={{
        boxShadow: '0 4px 8px rgba(0.2, 0.2, 0.2, 0.6)',
        
        backgroundColor: '#f9fafb', // Optional: To match Tailwind's bg-gray-50
        position: 'relative'
      }} className="bg-green-50 sm:rounded-lg text-center overflow-auto box-border mx-6 mb-32">

        <table className="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
          <thead className="text-xs text-white uppercase bg-green-600 dark:text-white">
            <tr>
              <th scope="col" className="px-6 py-3">Teacher name</th>
              <th scope="col" className="px-6 py-3">Country</th>
              <th scope="col" className="px-6 py-3">Gender</th>
              <th scope="col" className="px-6 py-3">Date of Birth</th>
              <th scope="col" className="px-6 py-3">Phone Number</th>
              <th scope="col" className="px-6 py-3">Email</th>
            </tr>
          </thead>

          <tbody>
            {
              isLoading ?
                <tr>
                  <td colSpan="7" className="px-6 py-4 text-center" style={{ fontWeight: 'bold', color: '#8B0000' }}>
                    <IconLoader className=' animate-spin mx-auto' />
                  </td>
                </tr> :
                teacher.length > 0 ? (
                  teacher.map((teacherItem, index) => (
                    <tr key={index} className="bg-green-100 border-b border-green-300 text-green-500 cursor-pointer"
                      onClick={() => handleOnclick(teacherItem.M05_UserId)}>
                      <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap dark:text-green-500">
                        {teacherItem.M05_UserName}
                      </th>
                      <td className="px-6 py-4">
                        {teacherItem.M05_CountryName}
                      </td>
                      <td className="px-6 py-4">
                        {teacherItem.M05_Gender}
                      </td>
                      <td className="px-6 py-4">
                        {teacherItem.M05_DOB}
                      </td>
                      <td className="px-6 py-4">
                        {teacherItem.M05_MobileNumber}
                      </td>
                      <td className="px-6 py-4">
                        {teacherItem.M05_Email}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 text-center" style={{ fontWeight: 'bold', color: '#8B0000' }}>
                      NO TEACHER FOUND
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <TeacherDetails
          id={selectedTeacherId}
          toggleModal={toggleModal}
          setModal={setIsModalOpen}

        />
      )}
    </div>

  )
}

export default Teacherlist

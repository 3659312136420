import React, { useState } from 'react';
import api from '../../components/adminAxios';
import { useNavigate } from 'react-router-dom';
import { ADMIN_URL_PATH } from '../../App';

function CoursePage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    M01_Title: '', 
    M01_Description: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!formData.M01_Title.trim()) {
      formErrors.M01_Title = 'Title is required';
    }
    if (!formData.M01_Description.trim()) {
      formErrors.M01_Description = 'Description is required';
    }
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await api.post('/courses', formData);
        if (response.data.success === true) {
          navigate(`/${ADMIN_URL_PATH}/course`);
        }
      } catch (error) {
        console.log('Error creating course:', error);
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div>
      <div 
        style={{ backgroundColor: '#dff6ec' }} 
        className='w-full min-h-screen flex justify-center items-center p-8 overflow-auto'
      >
        <div className="form-container w-full max-w-4xl">
          <h1 className="text-3xl font-semibold text-green-900 mb-10 text-center">Course Creation</h1>
          <form onSubmit={handleSubmit} className="space-y-5">
            <div className="flex flex-col sm:flex-row sm:space-x-5 space-y-5 sm:space-y-0">
              <div className="flex-1">
                <label className='block text-sm font-medium text-gray-700'>Title</label>
                <input
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500'
                  type="text"
                  name="M01_Title"
                  value={formData.M01_Title}
                  onChange={handleChange}
                />
                {errors.M01_Title && <span className="error-text text-red-600">{errors.M01_Title}</span>}
              </div>

              <div className="flex-1">
                <label className='block text-sm font-medium text-gray-700'>Description</label>
                <input
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500'
                  type="text"
                  name="M01_Description"
                  value={formData.M01_Description}
                  onChange={handleChange}
                />
                {errors.M01_Description && <span className="error-text text-red-600">{errors.M01_Description}</span>}
              </div>
            </div>

            <div className="mt-5">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CoursePage;

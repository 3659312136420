import React, { useEffect, useState } from 'react';
import api from '../../../components/adminAxios';
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_URL_PATH } from '../../../App';

function BatchEdit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [batchNames, setBatchNames] = useState([]);
  const [batchIdMap, setBatchIdMap] = useState({});
  const [formData, setFormData] = useState({
    M02_M01_CourseId: '',
    M02_Name: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const fetchId = async () => {
    try {
      const response = await api.get("/course_ids");
      setBatchIdMap(response.data.data);
      setBatchNames(Object.keys(response.data.data));
    } catch (error) {
      console.log(error);
    }
  };

  const findBatch = async () => {
    try {
      const response = await api.get(`/batches/${id}`);
      const batchData = response.data.data;
      setFormData({
        M02_M01_CourseId: batchData.M02_M01_CourseId,
        M02_Name: batchData.M02_Name
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchId();
    findBatch();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!formData.M02_M01_CourseId) {
      formErrors.M02_M01_CourseId = 'Course is required';
    }
    if (!formData.M02_Name.trim()) {
      formErrors.M02_Name = 'Description is required';
    }
    if (Object.keys(formErrors).length === 0) {
      handleOnSubmit();
    } else {
      setErrors(formErrors);
    }
  };

  const handleOnSubmit = async () => {
    try {
      const response = await api.put(`/batches/${id}`, formData);
      console.log(response, "-----------------------------------");
      navigate(`/${ADMIN_URL_PATH}/batch`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div 
        style={{ backgroundColor: '#dff6ec' }} 
        className='w-full min-h-screen flex justify-center items-center p-8 overflow-auto'
      >
        <div className="form-container w-full max-w-4xl">
          <h1 className="text-3xl font-semibold text-green-900 mb-10 text-center">Batch Edits</h1>
          <form onSubmit={handleSubmit} className="space-y-5">
            <div className="flex flex-col sm:flex-row sm:space-x-5 space-y-5 sm:space-y-0">
              <div className="flex-1">
                <label className='block text-sm font-medium text-gray-700'>Batch</label>
                <input
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500'
                  type="text"
                  name="M02_Name"
                  value={formData.M02_Name}
                  onChange={handleChange}
                />
                {errors.M02_Name && <span className="error-text text-red-600">{errors.M02_Name}</span>}
              </div>

              <div className="flex-1">
                <label className='block text-sm font-medium text-gray-700'>Course</label>
                <select
                  name="M02_M01_CourseId"
                  onChange={handleChange}
                  value={formData.M02_M01_CourseId || ''}
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500'
                >
                  <option value="" disabled>Select a course</option>
                  {batchNames.map(name => (
                    <option value={batchIdMap[name]} key={name}>{name}</option>
                  ))}
                </select>
                {errors.M02_M01_CourseId && <span className="error-text text-red-600">{errors.M02_M01_CourseId}</span>}
              </div>
            </div>

            <div className="col-span-full mt-5">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BatchEdit;

import React, { useState, useEffect } from 'react';
import './Carousel.css'

export const CarouselLatestImages = () => {
    // Initialize items with is_active flag
    const [items, setItems] = useState([
        { id: 1, title: "1Dirlis Ertugrul", content: "Lorem ipsum dolor sit amet.", image: "https://i.postimg.cc/rpTXWBb0/MV5-BOTQ0-NDY0-Y2-Mt-MTdi-Yi00-Zjgz-LWJj-Nm-It-Nz-Yw-Y2-Nk-Zjll-Ym-I1-Xk-Ey-Xk-Fqc-Gde-QXVy-NDg4-Mjkz-NDk-V1.jpg", is_active: true },
        { id: 2, title: "2Dirlis Ertugrul", content: "Lorem ipsum dolor sit amet.", image: "https://i.postimg.cc/pVsWvLS0/ffbd73fd0e5e40a8443e11c9d2b29d5d.jpg", is_active: false },
        { id: 3, title: "3Dirlis Ertugrul", content: "Lorem ipsum dolor sit amet.", image: "https://i.postimg.cc/tgFRGt0P/MV5-BMGM2-Zm-E4-Yz-Mt-OTBm-NS00-Nm-E4-LWI4-NTUt-Mjg3-ZTJj-ZDYw-ZTc5-Xk-Ey-Xk-Fqc-Gde-QXVy-NDg4-Mjkz-NDk-V1.jpg", is_active: false },
        { id: 4, title: "4Dirlis Ertugrul", content: "Lorem ipsum dolor sit amet.", image: "https://i.postimg.cc/wv6zKCQ8/MV5-BNj-I4-Yj-Jl-ZTct-Mj-U5-NS00-MGFh-LWFk-Zjgt-OTlj-N2-Y4-Nz-I1-Mj-A4-Xk-Ey-Xk-Fqc-Gde-QXVy-NDg4-Mjkz-NDk-V1.jpg", is_active: false }
    ]);

    const handleNext = () => {
        setItems(prevItems => {
            // Remove the first item and add it to the end
            const [firstItem, ...restItems] = prevItems;
            return [...restItems, firstItem];
        });
    };

    const [activeIndex, setActiveIndex] = useState(0);

    // const handleNext = () => {
    //     setActiveIndex((prevIndex) => {
    //         console.log((prevIndex + 1) % items.length)
    //         return (prevIndex + 1) % items.length
    //     });
    // };

    const handlePrev = () => {
        setItems(prevItems => {
            // Remove the last item and add it to the beginning
            const lastIndex = prevItems.length - 1;
            const [lastItem, ...restItems] = [...prevItems].reverse();
            return [lastItem, ...restItems.reverse()];
        });
    };

    const handleDotClick = (index) => {
        setActiveIndex(index);
    };

    const handleItemClick = (index) => {
        if (index !== 0) {
            setActiveIndex((prevIndex) => (prevIndex + index) % items.length);
        }
    };

    return (
        <div className='  latestCarouselConverted '>
            <p className='mt-16 mb-6 font-light text-[#24485D] text-start' style={{fontSize:'clamp(30px, 3vw, 40px)'}}>Latest</p>
            <div className="dp-wrap">
                <div id="dp-slider">
                    {items.map((item, index) => (
                        <div
                            key={item.id}
                            className="dp_item w-full sm:w-[80%]"
                            data-class={item.id}
                            data-position={item.position}
                            onClick={() => handleItemClick(index)}
                            style={{
                                zIndex: items.length - index,
                                transform: index === 0 ? 'none' : `rotateY(0deg) translateX(${index * 10}%) scale(${1 - index * 0.1})`,
                                cursor: index === 0 ? 'default' : 'pointer'
                            }}
                        >
                            {/* <div className="dp-content">
                                <h2>{item.title}</h2>
                                <p>{item.content}</p>
                                <a href="#" className="site-btn ">Read More…</a>
                            </div> */}
                            <div className="dp-img">
                                <img className="img-fluid" src={item.image} alt="investing" />
                            </div>
                        </div>
                    ))}
                    <div className=' absolute w-full sm:w-[80%]   max-h-[78vh] aspect-[1.7]'>
                        <span id="dp-next" onClick={handleNext}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.401 51.401">
                                <defs>
                                    <style>
                                        {`.cls-1 {
                fill: none;
                stroke: gold;
                stroke-miterlimit: 10;
                stroke-width: 7px;
              }`}
                                    </style>
                                </defs>
                                <path id="Rectangle_4_Copy" data-name="Rectangle 4 Copy" className="cls-1" d="M32.246,0V33.178L0,31.953" transform="translate(0.094 25.276) rotate(-45)" />
                            </svg>
                        </span>
                        <span id="dp-prev" onClick={handlePrev}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.401 51.401">
                                <defs>
                                    <style>
                                        {`.cls-1 {
                fill: none;
                stroke: gold;
                stroke-miterlimit: 10;
                stroke-width: 7px;
              }`}
                                    </style>
                                </defs>
                                <path id="Rectangle_4_Copy" data-name="Rectangle 4 Copy" className="cls-1" d="M32.246,0V33.178L0,31.953" transform="translate(0.094 25.276) rotate(-45)" />
                            </svg>
                        </span>
                    </div>
                </div>

                {/* <ul id="dp-dots">
                    {items.map((item, index) => (
                        <li
                            key={item.id}
                            data-class={item.id}
                            className={item.id === items[0].id ? 'active' : ''}
                            onClick={() => handleDotClick(items.findIndex(i => i.id === item.id))}
                        ></li>
                    ))}
                </ul> */}
            </div>
        </div>
    );
};
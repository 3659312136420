import React from 'react'
import { IconUserPlus,IconDeviceImac, IconQuestionMark, IconFileCheck } from '@tabler/icons-react';

export default function HowitWork() {
    return (
        <div className='bg-pumpkinToast-500 py-10 text-white flex flex-col justify-center gap-10'>
            <h1 style={{fontSize:'clamp(20px, 3vw, 36px)'}} className='md:font-bold font-semibold'>How It Works</h1>
            <div className='lg:px-20 md:px-10 px-5 flex flex-wrap gap-y-5'>
                <div className='md:w-1/4 w-1/2 text-left flex flex-col items-center md:items-start gap-2 md:px-5 px-2'>
                    <div className='w-[50px] h-[50px] bg-white rounded-full md:mb-4  text-black flex justify-center items-center'><IconUserPlus stroke={2} /></div>
                    <h1>Sign Up</h1>
                    <p className=' text-gray-300 md:text-left text-center' style={{ fontSize: 'clamp(10px, 1.2vw, 14px)' }}>Montes vivamus curae quisque et primis pretium nullam. Congue dis convallis eget ipsum cubilia ante.</p>
                </div>
                <div className='md:w-1/4 w-1/2 text-left flex flex-col items-center md:items-start gap-2 md:px-5 px-2'>
                <div className='w-[50px] h-[50px] bg-white rounded-full md:mb-4 text-black flex justify-center items-center'><IconDeviceImac stroke={2} /></div>
                    <h1>Get Access</h1>
                    <p className='  text-gray-300 md:text-left text-center' style={{ fontSize: 'clamp(10px, 1.2vw, 14px)' }}>Access to question bank of over 2000 questions</p>
                </div>
                <div className='md:w-1/4 w-1/2 text-left flex flex-col items-center md:items-start gap-2 md:px-5 px-2'>
                <div className='w-[50px] h-[50px] bg-white rounded-full md:mb-4 text-black flex justify-center items-center'><IconQuestionMark stroke={2} /></div>
                    <h1>Practice quastions</h1>
                    <p className='  text-gray-300 md:text-left text-center' style={{ fontSize: 'clamp(10px, 1.2vw, 14px)' }}>Prepare for the exam in multiple modes of revision and track your progress with your dashboard</p>
                </div>
                <div className='md:w-1/4 w-1/2 text-left flex flex-col items-center md:items-start gap-2 md:px-5 px-2'>
                <div className='w-[50px] h-[50px] bg-white rounded-full md:mb-4 text-black flex justify-center items-center'><IconFileCheck stroke={2} /></div>
                    <h1>Get Result</h1>
                    <p className='  text-gray-300 md:text-left text-center' style={{ fontSize: 'clamp(10px, 1.2vw, 14px)' }}>compare your results with your peers' with advanced analytics</p>
                </div>
            </div>
            <button className='border border-white w-[130px] self-center rounded-full py-1 hover:bg-white hover:text-pumpkinToast-500 shadow-xl'>Start Now</button>
        </div>
    )
}

import React from 'react'
import { Success } from './successCard';
import { HomeContent } from './homeContainer';
import HowitWork from './howitWork';
import { Managment } from './Managment';
import SupportForm from './supportForm';
import Footer from './footer';

export function HomePage() {
    return (
        <div className='w-full text-center min-h-screen relative pt-[60px]'>
            <div className='bg-[rgba(255,200,100,1)] mt-[60px] w-[300px] h-[300px] absolute blur-[160px] z-0 top-[100px]'></div>
            <HomeContent />
            <Success />
            <HowitWork />
            <Managment />
            <SupportForm />
            <Footer />
        </div>
    )
}

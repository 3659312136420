
"use client";

import { Button, Drawer } from "flowbite-react";
import { useState } from "react";
import { HiBars2, HiSquaresPlus } from "react-icons/hi2";
import { Link, Outlet } from "react-router-dom";

export function OffcanvasComponent() {
  const [isOpen, setIsOpen] = useState(true);


  const handleClose = () => setIsOpen(false);
  const customTheme = {
    "root": {
      "base": "fixed z-40 overflow-y-auto bg-white p-4 transition-transform dark:bg-gray-800",
      "backdrop": "fixed inset-0 z-30 bg-gray-900/50 dark:bg-gray-900/80",
      "edge": "bottom-16",
      "position": {
        "top": {
          "on": "left-0 right-0 top-0 w-full transform-none",
          "off": "left-0 right-0 top-0 w-full -translate-y-full"
        },
        "right": {
          "on": "right-0 top-0 h-screen w-80 transform-none",
          "off": "right-0 top-0 h-screen w-80 translate-x-full"
        },
        "bottom": {
          "on": "bottom-0 left-0 right-0 w-full transform-none",
          "off": "bottom-0 left-0 right-0 w-full translate-y-full"
        },
        "left": {
          "on": "left-0 top-0 h-screen w-80 transform-none",
          "off": "left-0 top-0 h-screen w-80 -translate-x-full"
        }
      }
    },
    "header": {
      "inner": {
        "closeButton": "absolute end-2.5 top-2.5 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
        "closeIcon": "h-4 w-4",
        "titleIcon": "me-2.5 h-4 w-4",
        "titleText": "mb-4 inline-flex items-center text-base font-semibold text-white dark:text-white"
      },
      "collapsed": {
        "on": "hidden",
        "off": "block"
      }
    },
    "items": {
      "base": ""
    }
  };

  return (
    <>
      <Outlet />
      <div className=" fixed bottom-5 right-5">
        <Drawer edge open={isOpen} onClose={handleClose} position="bottom" className="p-0" theme={customTheme}>
          <Drawer.Header
            closeIcon={HiBars2}
            title="Add More"
            titleIcon={HiSquaresPlus}
            onClick={() => setIsOpen(!isOpen)}
            className="cursor-pointer bg-green-950 px-4 pt-4 !text-white"
          />
          <Drawer.Items className="p-4 bg-green-950">
            <div className="grid grid-cols-3 gap-4 p-4 lg:grid-cols-4">
              <Link to="adminpanel" className="group cursor-pointer rounded-lg bg-gray-50 p-4 hover:bg-[#1a4834] dark:bg-gray-700 dark:hover:bg-gray-600">
                <div className="mx-auto mb-2 flex h-[48px] max-h-[48px] w-[48px] max-w-[48px] items-center justify-center rounded-full bg-gray-200 p-2 group-hover:bg-[#e5e7eb36] dark:bg-gray-600">
                  <svg className="inline h-5 w-5 text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                  </svg>
                </div>
                <div className="text-center font-medium  text-gray-500 group-hover:text-[#ffffffa6] ] dark:text-gray-400">Students</div>
              </Link>

              <Link to="teacher" className="group cursor-pointer rounded-lg bg-gray-50 p-4 hover:bg-[#1a4834]  dark:bg-gray-700 dark:hover:bg-gray-600">
                <div className="mx-auto mb-2 flex h-[48px] max-h-[48px] w-[48px] max-w-[48px] items-center justify-center rounded-full bg-gray-200 p-2 group-hover:bg-[#e5e7eb36] dark:bg-gray-600">
                  <svg className="inline h-5 w-5 text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm-1.391 7.361.707-3.535a3 3 0 0 1 .82-1.533L7.929 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h4.259a2.975 2.975 0 0 1-.15-1.639ZM8.05 17.95a1 1 0 0 1-.981-1.2l.708-3.536a1 1 0 0 1 .274-.511l6.363-6.364a3.007 3.007 0 0 1 4.243 0 3.007 3.007 0 0 1 0 4.243l-6.365 6.363a1 1 0 0 1-.511.274l-3.536.708a1.07 1.07 0 0 1-.195.023Z" />
                  </svg>
                </div>
                <div className="text-center font-medium text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400">Teacher</div>
              </Link>


              <Link to="division" className="group cursor-pointer rounded-lg bg-gray-50 p-4 hover:bg-[#1a4834]  dark:bg-gray-700 dark:hover:bg-gray-600">
                <div className="mx-auto mb-2 flex h-[48px] max-h-[48px] w-[48px] max-w-[48px] items-center justify-center rounded-full bg-gray-200 p-2 group-hover:bg-[#e5e7eb36] dark:bg-gray-600">
                  <svg className="inline h-5 w-5 text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1M2 5h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
                  </svg>
                </div>
                <div className="text-center font-medium text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400">Division</div>
              </Link>

              <Link to="class" className="group cursor-pointer rounded-lg bg-gray-50 p-4 hover:bg-[#1a4834]  dark:bg-gray-700 dark:hover:bg-gray-600">
                <div className="mx-auto mb-2 flex h-[48px] max-h-[48px] w-[48px] max-w-[48px] items-center justify-center rounded-full bg-gray-200 p-2 group-hover:bg-[#e5e7eb36] dark:bg-gray-600">
                  <svg className="inline h-5 w-5 text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1M2 5h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
                  </svg>
                </div>
                <div className="text-center font-medium text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400">Class</div>
              </Link>


              <Link to="batch" className="group cursor-pointer rounded-lg bg-gray-50 p-4 hover:bg-[#1a4834]  dark:bg-gray-700 dark:hover:bg-gray-600">
                <div className="mx-auto mb-2 flex h-[48px] max-h-[48px] w-[48px] max-w-[48px] items-center justify-center rounded-full bg-gray-200 p-2 group-hover:bg-[#e5e7eb36] dark:bg-gray-600">
                  <svg className="inline h-5 w-5 text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 20">
                    <path d="M13.383.076a1 1 0 0 0-1.09.217L11 1.586 9.707.293a1 1 0 0 0-1.414 0L7 1.586 5.707.293a1 1 0 0 0-1.414 0L3 1.586 1.707.293A1 1 0 0 0 0 1v18a1 1 0 0 0 1.707.707L3 18.414l1.293 1.293a1 1 0 0 0 1.414 0L7 18.414l1.293 1.293a1 1 0 0 0 1.414 0L11 18.414l1.293 1.293A1 1 0 0 0 14 19V1a1 1 0 0 0-.617-.924ZM10 15H4a1 1 0 1 1 0-2h6a1 1 0 0 1 0 2Zm0-4H4a1 1 0 1 1 0-2h6a1 1 0 1 1 0 2Zm0-4H4a1 1 0 0 1 0-2h6a1 1 0 1 1 0 2Z" />
                  </svg>
                </div>
                <div className="text-center font-medium text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" >Batch</div>
              </Link>

              <Link to="course" className="group cursor-pointer rounded-lg bg-gray-50 p-4 hover:bg-[#1a4834]  dark:bg-gray-700 dark:hover:bg-gray-600">
                <div className="mx-auto mb-2 flex h-[48px] max-h-[48px] w-[48px] max-w-[48px] items-center justify-center rounded-full bg-gray-200 p-2 group-hover:bg-[#e5e7eb36] dark:bg-gray-600">
                  <svg className="inline h-5 w-5 text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                  </svg>
                </div>
                <div className="text-center font-medium text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" >course</div>
              </Link>

              <Link to="role" className="group cursor-pointer rounded-lg bg-gray-50 p-4 hover:bg-[#1a4834] dark:bg-gray-700 dark:hover:bg-gray-600">
                <div className="mx-auto mb-2 flex h-[48px] max-h-[48px] w-[48px] max-w-[48px] items-center justify-center rounded-full bg-gray-200 p-2 group-hover:bg-[#e5e7eb36] dark:bg-gray-600">
                  <svg className="inline h-5 w-5 text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                    <path d="M18 0H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM9 6v2H2V6h7Zm2 0h7v2h-7V6Zm-9 4h7v2H2v-2Zm9 2v-2h7v2h-7Z" />
                  </svg>
                </div>
                <div className="text-center font-medium text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400">Role</div>
              </Link>


              <Link to="students" className="group cursor-pointer rounded-lg bg-gray-50 p-4 hover:bg-[#1a4834]  dark:bg-gray-700 dark:hover:bg-gray-600">
                <div className="mx-auto mb-2 flex h-[48px] max-h-[48px] w-[48px] max-w-[48px] items-center justify-center rounded-full bg-gray-200 p-2 group-hover:bg-[#e5e7eb36] dark:bg-gray-600">
                  <svg className="inline h-5 w-5 text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                  </svg>
                </div>
                <div className="text-center font-medium text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400">Students</div>
              </Link>



              <div className="group cursor-pointer rounded-lg bg-gray-50 p-4 hover:bg-[#1a4834]  dark:bg-gray-700 dark:hover:bg-gray-600">
                <div className="mx-auto mb-2 flex h-[48px] max-h-[48px] w-[48px] max-w-[48px] items-center justify-center rounded-full bg-gray-200 p-2 group-hover:bg-[#e5e7eb36] dark:bg-gray-600">
                  <svg className="inline h-5 w-5 text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2" />
                  </svg>
                </div>
                <div className="text-center font-medium text-gray-500 group-hover:text-[#ffffffa6] dark:text-gray-400">Custom</div>
              </div>
            </div>
          </Drawer.Items>
        </Drawer>
      </div>
    </>
  );
}
